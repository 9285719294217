import { BlockActionType } from "../../../../types/home/leftBlock";
import { MagnifyingGlassIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import { GroupMenuStateType, GroupType } from "../../../../types/groups";
import { StudentInNoteType, StudentNoteType } from "../../../../types/notes";
import { BASEURL } from "../../../../constants";
import { GroupMenu } from "./groupMenu";
import { StudentCard } from "./studentCard";
import { StudentType } from "../../../../types/student";
import axios from "axios";
import classNames from "classnames";
import clsx from "clsx";
import { debounce } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

const SortBy = [
  { label: "Select", value: "" },
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Notes", value: "note_count" },
];

const BlockActions: BlockActionType[] = [
  {
    name: "Select All",
    value: "select_all",
    icon: UserGroupIcon,
    onClick: (selectStudent, allStudents) => {
      selectStudent(allStudents);
    },
  },
  {
    name: "Deselect All",
    value: "deselect_all",
    icon: UserGroupIcon,
    onClick: (selectStudent) => {
      selectStudent(undefined);
    },
  },
  {
    name: "Search",
    value: "search",
    icon: MagnifyingGlassIcon,
  },
];

export const LeftBlock = ({
  selectedStudents,
  selectStudent,
  selectedStudentNotes,
  selectStudentNotes,
  editStudentSubNoteState,
  refetchStudents,
}: {
  selectedStudents: StudentType[] | StudentInNoteType[];
  selectStudent: (student: StudentType) => void;
  selectedStudentNotes: StudentType | null;
  selectStudentNotes: (student: StudentType) => void;
  editStudentSubNoteState: StudentNoteType | null;
  refetchStudents: number;
}) => {
  const [actions, setActions] = useState({
    sortBy: SortBy[0].value,
  });
  const [search, setSearch] = useState("");
  const [groupMenuData, setGroupMenuData] = useState<GroupMenuStateType>({
    open: false,
    selected: { id: "0", name: "All", userId: "", students: [] },
    groups: [],
  });

  const debouncedSearch = debounce((value) => {
    setSearch(value);
  }, 800);

  const {
    isLoading: isLoadingStudents,
    data: studentsData,
    error: studentsError,
    refetch,
  } = useQuery(
    ["students", search, actions.sortBy, groupMenuData.selected],
    async (): Promise<StudentType[]> => {
      if (search) {
        return [];
      }

      const selectedGroupId = groupMenuData.selected.id;

      const res = await axios.get(`${BASEURL}/v1/students`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          sort_by: actions.sortBy,
          ...(selectedGroupId !== "0" ? { group: selectedGroupId } : {}),
        },
      });

      return res.data;
    }
  );

  const {
    isLoading: isLoadingSearch,
    data: searchData,
    error: searchError,
  } = useQuery(
    ["studentsSearch", search, actions.sortBy, groupMenuData.selected],
    async (): Promise<StudentType[]> => {
      if (!search) {
        return [];
      }

      const selectedGroupId = groupMenuData.selected.id;

      const res = await axios.get(`${BASEURL}/v1/students`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          search,
          sort_by: actions.sortBy,
          ...(selectedGroupId !== "0" ? { group: selectedGroupId } : {}),
        },
      });

      return res.data;
    }
  );

  useEffect(() => {
    const updateStudents = () => {
      refetch();
    };

    if (refetchStudents > 0) {
      updateStudents();
    }
  }, [refetchStudents, refetch]);

  function changeSortBy(e: React.ChangeEvent<HTMLSelectElement>) {
    setActions((prev) => ({ ...prev, sortBy: e.target.value }));
  }

  function changeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    debouncedSearch(e.target.value);
  }

  function closeGroupMenu() {
    setGroupMenuData((prev) => ({ ...prev, open: false }));
  }

  function switchGroupMenu() {
    setGroupMenuData((prev) => ({ ...prev, open: !prev.open }));
  }

  function selectGroup(group: GroupType) {
    setGroupMenuData((prev) => ({ ...prev, open: false, selected: group }));
  }

  function setGroupsData(groups: GroupType[]) {
    setGroupMenuData((prev) => ({
      ...prev,
      groups: groups,
    }));
  }

  return (
    <div className="relative grid w-full max-h-[80vh] min-h-[80vh] gap-6 p-6 border-2 border-gray-300 border-solid rounded-lg md:w-3/4 grid-rows-[auto,1fr,auto]">
      <GroupMenu
        groupMenuData={groupMenuData}
        closeGroupMenu={closeGroupMenu}
        switchGroupMenu={switchGroupMenu}
        selectGroup={selectGroup}
        setGroupsData={setGroupsData}
      />
      <div className="grid items-center justify-between w-full grid-cols-1 gap-4 text-sm md:grid-cols-2">
        <div>
          <p className="col-span-3">
            Selected:{" "}
            {selectedStudents.map((student) => student.first_name).join(", ")}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="sort">Sort By:</label>
          <select
            name="sort"
            onChange={changeSortBy}
            value={actions.sortBy}
            className="rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            {SortBy.map((sort) => (
              <option key={sort.value} value={sort.value}>
                {sort.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(min(150px,100%),1fr))] gap-4 overflow-y-scroll auto-rows-max w-full md:w-[104%] py-1">
        {(isLoadingStudents || isLoadingSearch) && (
          <div className="self-center col-span-2 justify-self-center">
            Loading...
          </div>
        )}
        {studentsError || searchError ? (
          <div className="self-center col-span-2 text-red-500 justify-self-center">
            Error
          </div>
        ) : null}

        {search && searchData && (
          <>
            {searchData.length === 0 ? (
              <div className="self-center col-span-2 justify-self-center">
                No Students Found
              </div>
            ) : (
              searchData.map((student: StudentType) => (
                <StudentCard
                  key={student.id}
                  selectedStudents={selectedStudents}
                  student={student}
                  selectStudent={selectStudent}
                  selectedStudentNotes={selectedStudentNotes}
                  selectStudentNotes={selectStudentNotes}
                  editStudentSubNoteState={editStudentSubNoteState}
                />
              ))
            )}
          </>
        )}

        {!search &&
          studentsData &&
          studentsData.map((student: StudentType) => (
            <StudentCard
              key={student.id}
              selectedStudents={selectedStudents}
              student={student}
              selectStudent={selectStudent}
              selectedStudentNotes={selectedStudentNotes}
              selectStudentNotes={selectStudentNotes}
              editStudentSubNoteState={editStudentSubNoteState}
            />
          ))}
      </div>
      <div className="grid justify-between grid-cols-2 gap-4">
        {BlockActions.map((action) => {
          const IconComponent = action.icon;
          return (
            <div
              className={classNames(
                clsx(
                  "flex cursor-pointer items-center",
                  action.value === "search"
                    ? "col-span-2 w-full"
                    : "gap-2 w-max"
                )
              )}
              key={action.name}
            >
              {action.value === "search" ? (
                <div className="relative flex items-center w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-2 cursor-pointer">
                    <IconComponent className="w-5 h-5" />
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="w-full px-2 py-1 pl-8 border-gray-300 rounded-lg border-1 focus:ring-1 focus:ring-indigo-600"
                    onChange={changeSearch}
                  />
                </div>
              ) : (
                <>
                  <IconComponent
                    className="h-5"
                    onClick={() =>
                      action.onClick!(
                        selectStudent,
                        searchData?.length !== 0 ? searchData : studentsData
                      )
                    }
                  />
                  <p
                    className="text-base md:w-24 hover:text-indigo-600"
                    onClick={() =>
                      action.onClick!(
                        selectStudent,
                        searchData?.length !== 0 ? searchData : studentsData
                      )
                    }
                  >
                    {action.name}
                  </p>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
