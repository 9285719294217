import { NewNoteType, StudentInNoteType } from "../../../../types/notes";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";

import { BASEURL } from "../../../../constants";
import JSZip from "jszip";
import { PDFComponent } from "../pdf/pdfComponent";
import { StudentType } from "../../../../types/student";
import axios from "axios";
import classNames from "classnames";
import clsx from "clsx";
import moment from "moment";
import { saveAs } from "file-saver";

export const ExportButton = ({
  selectedNotes,
  selectedStudents,
  startDate,
  endDate,
}: {
  selectedNotes: NewNoteType[];
  selectedStudents: StudentType[] | StudentInNoteType[];
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const [disabled, setDisabled] = useState<boolean>(true);

  const getStudentFromNote = (notes: NewNoteType[], studentId: string) => {
    for (let note of notes) {
      const studentOnNote = note.students_on_notes.find(
        (student) => student.student.id === studentId
      );
      if (studentOnNote) return studentOnNote.student;
    }
    return null;
  };

  const getNotesForStudent = (notes: NewNoteType[], studentId: string) => {
    return notes.filter((note) =>
      note.students_on_notes.some((student) => student.student.id === studentId)
    );
  };

  const fetchNotes = async (): Promise<NewNoteType[]> => {
    try {
      const { data } = await axios.get(`${BASEURL}/v1/notes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          students: selectedStudents.map((student) => student.id).join(","),
        },
      });
      return data;
    } catch (error) {
      console.error("Failed to fetch notes:", error);
      return [];
    }
  };

  async function downloadZip() {
    if (disabled) return null;

    setDisabled(true);

    const notesFromSelectedStudents =
      selectedNotes.length > 0 ? [] : await fetchNotes();

    const studentsArray = selectedNotes.map((note) =>
      note.students_on_notes.map((student) => student.student.id)
    );

    const uniqueStudents =
      selectedNotes.length > 0
        ? [...new Set(studentsArray.flat())]
        : selectedStudents.map((student) => student.id);

    const zip = new JSZip();

    uniqueStudents.forEach((studentId) => {
      const studentNotes =
        selectedNotes.length > 0 ? selectedNotes : notesFromSelectedStudents;

      const student = getStudentFromNote(studentNotes, studentId);

      if (student) {
        const studentFullName = `${student.first_name} ${student.last_name}`;
        const notes = getNotesForStudent(studentNotes, studentId);
        zip.file(
          `${studentFullName}.pdf`,
          pdf(
            <PDFComponent
              notes={notes}
              studentFullName={studentFullName}
              student={student}
            />
          ).toBlob()
        );
      }
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(
        content,
        `notes-${moment(startDate).format("MM-DD-YYYY")}-${moment(
          endDate
        ).format("MM-DD-YYYY")}.zip`
      );
    });

    setDisabled(false);
  }

  useEffect(() => {
    const isExportAllowed =
      selectedStudents.length > 0 ||
      (selectedNotes.length > 0 && startDate && endDate);
    setDisabled(!isExportAllowed);
  }, [endDate, selectedNotes, selectedStudents, startDate]);

  return (
    <button
      className={classNames(
        clsx(
          "text-sm mx-auto md:mx-0 w-max font-semibold leading-6 py-1 px-4 rounded-lg border border-solid border-gray-300",
          disabled
            ? "text-gray-900 bg-white cursor-not-allowed"
            : "bg-indigo-600 text-white hover:bg-indigo-700 cursor-pointer"
        )
      )}
      disabled={disabled}
      onClick={downloadZip}
    >
      Export
    </button>
  );
};
