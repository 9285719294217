import { EditStudentType, StudentType } from "../../../../types/student";
import {
  NewNoteType,
  StudentInNoteType,
  StudentNoteType,
} from "../../../../types/notes";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BASEURL } from "../../../../constants";
import { GRADES_OPTIONS } from "./addStudentPanel";
import { NoteDecideWhatToRender } from "./note";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import classNames from "classnames";
import clsx from "clsx";
import moment from "moment";

const SECTIONS = [
  {
    block: 1,
    children: [
      {
        name: "First Name",
        value: "first_name",
      },
      {
        name: "Last Name",
        value: "last_name",
      },
    ],
  },
  {
    block: 2,
    children: [
      {
        name: "Grade",
        value: "grade",
        type: "select",
        options: GRADES_OPTIONS,
      },
    ],
  },
  {
    block: 3,
    children: [
      {
        name: "Parent/Guardian 1",
        value: "guardian_a_full_name",
      },
      {
        name: "Phone Number",
        value: "guardian_a_phone",
      },
      {
        name: "Email",
        value: "guardian_a_email",
      },
    ],
  },
  {
    block: 4,
    children: [
      {
        name: "Parent/Guardian 2",
        value: "guardian_b_full_name",
      },
      {
        name: "Phone Number",
        value: "guardian_b_phone",
      },
      {
        name: "Email",
        value: "guardian_b_email",
      },
    ],
  },
];

export const StudentDetails = ({
  selectedStudentNotes,
  selectedNotes,
  selectNote,
  editStudentSubNoteState,
  handleEditStudentSubNoteState,
}: {
  selectedStudents: StudentType[] | StudentInNoteType[];
  selectedStudentNotes: StudentType | null;
  selectedNotes: NewNoteType[];
  selectNote: (note: NewNoteType | undefined) => void;
  editStudentSubNoteState: StudentNoteType | null;
  handleEditStudentSubNoteState: (note: StudentNoteType | null) => void;
}) => {
  const [editableData, setEditableData] = useState<EditStudentType>({
    id: "",
    first_name: "",
    last_name: "",
    grade: null,
    guardian_a_full_name: "",
    guardian_a_relationship: "",
    guardian_a_phone: "",
    guardian_a_email: "",
    guardian_b_full_name: "",
    guardian_b_relationship: "",
    guardian_b_phone: "",
    guardian_b_email: "",
  });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isArchived, setIsArchived] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const {
    isLoading: isStudentPhotoLoading,
    data: studentPhotoData,
    isError: isStudentPhotoError,
  } = useQuery({
    queryKey: [selectedStudentNotes?.id, "photo"],
    queryFn: async () => {
      const req = await axios.get(`${BASEURL}/v1/photos`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          student: selectedStudentNotes?.id,
        },
      });

      return req.data;
    },
    retry: 0,
  });

  const {
    data: studentData,
    isLoading: isStudentLoading,
    isError: isStudentError,
  } = useQuery([selectedStudentNotes?.id, "details"], async () => {
    const req = await axios.get(
      `${BASEURL}/v1/students/${selectedStudentNotes?.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    return req.data;
  });

  const {
    data: studentNotesData,
    isLoading: isLoadingNotes,
    isError: isErrorNotes,
  } = useQuery([selectedStudentNotes, "notes"], async () => {
    const req = await axios.get(`${BASEURL}/v1/notes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      params: {
        students: selectedStudentNotes?.id,
      },
    });

    return req.data;
  });

  const updateStudentMutation = useMutation(
    (newData: EditStudentType) =>
      axios.patch(
        `${BASEURL}/v1/students/${selectedStudentNotes?.id}`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([selectedStudentNotes?.id, "details"]);
      },
      onError: (error) => {
        console.error("Failed to update student details:", error);
      },
    }
  );

  const toggleArchiveMutation = useMutation(
    ({ is_archived }: { is_archived: boolean }) =>
      axios.patch(
        `${BASEURL}/v1/students/${selectedStudentNotes?.id}`,
        { is_archived },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([selectedStudentNotes?.id, "details"]);
      },
      onError: (error) => {
        console.error("Failed to update student details:", error);
      },
    }
  );

  const handleSaveClick = () => {
    updateStudentMutation.mutate(editableData);
    setIsEditing(false);
  };

  useEffect(() => {
    if (studentData) {
      setEditableData(studentData);
      setIsArchived(studentData.is_archived);
    }
  }, [studentData]);

  const handleInputChange = (key: string, value: string) => {
    setEditableData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setEditableData(studentData);
    setIsEditing(false);
  };

  const handleArchiveClick = () => {
    toggleArchiveMutation.mutate({ is_archived: !isArchived });
    setIsArchived(!isArchived);
  };

  return (
    <div className="flex flex-col row-span-3 gap-4">
      <div className="flex flex-col items-center justify-center gap-4">
        {isStudentPhotoLoading || isStudentPhotoError || !studentPhotoData ? (
          <UserCircleIcon className="w-16 h-16 text-gray-400" />
        ) : (
          <img
            src={studentPhotoData.url}
            alt={studentData?.first_name}
            className="w-16 h-16 rounded-full"
          />
        )}
      </div>
      {isStudentLoading ? (
        <div className="flex flex-col items-center justify-center w-full h-full row-span-3 gap-4">
          <h1 className="text-base font-semibold text-gray-900">Loading...</h1>
        </div>
      ) : isStudentError ? (
        <div className="flex flex-col items-center justify-center w-full h-full row-span-3 gap-4">
          <h1 className="text-base font-semibold text-gray-900">Error</h1>
        </div>
      ) : (
        SECTIONS.map((section) => (
          <div className="flex flex-col gap-4" key={section.block}>
            <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-3">
              {section.children.map((child) => {
                if ("type" in child && child.type === "select") {
                  return (
                    <div key={child.value}>
                      <h1 className="text-sm font-semibold text-gray-900">
                        {child.name}
                      </h1>
                      <select
                        className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md"
                        onChange={(event) =>
                          handleInputChange(child.value, event.target.value)
                        }
                        value={editableData[child.value] ?? ""}
                        disabled={!isEditing}
                      >
                        {child.options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  );
                } else {
                  return (
                    <div key={child.value}>
                      <h1 className="text-sm font-semibold text-gray-900">
                        {child.name}
                      </h1>
                      <input
                        type="text"
                        className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md"
                        value={editableData[child.value] ?? ""}
                        onChange={(e) =>
                          handleInputChange(child.value, e.target.value)
                        }
                        disabled={!isEditing}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ))
      )}
      <div
        className={classNames(
          clsx("grid grid-cols-2 items-center justify-center gap-4")
        )}
      >
        {isEditing ? (
          <>
            <button
              type="button"
              className="px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-600"
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              type="button"
              className="px-4 py-2 text-sm font-semibold text-white bg-red-500 rounded-md hover:bg-red-600"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="col-span-2 px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-600"
              onClick={handleEditClick}
            >
              Edit Information
            </button>
            <button
              type="button"
              className="col-span-2 px-4 py-2 text-sm font-semibold text-white bg-red-600 rounded-md hover:bg-red-700"
              onClick={handleArchiveClick}
            >
              {isArchived ? "Unarchive" : "Archive"} Student
            </button>
          </>
        )}
      </div>
      <div className="grid gap-4 overflow-y-scroll w-full grid-cols-[repeat(auto-fit,minmax(min(200px,100%),1fr))]">
        <NoteDecideWhatToRender
          isLoadingNotes={isLoadingNotes}
          notesError={isErrorNotes}
          notesData={studentNotesData}
          startDate={moment().toDate()}
          viewMonth={true}
          selectedNotes={selectedNotes}
          selectNote={selectNote}
          editStudentSubNoteState={editStudentSubNoteState}
          handleEditStudentSubNoteState={handleEditStudentSubNoteState}
        />
      </div>
    </div>
  );
};
