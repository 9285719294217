import {
  InformationCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { StudentInNoteType, StudentNoteType } from "../../../../types/notes";

import { BASEURL } from "../../../../constants";
import { StudentType } from "../../../../types/student";
import axios from "axios";
import classNames from "classnames";
import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";
import { GRADES_OPTIONS } from "../rightBlock/addStudentPanel";

export const StudentCard = ({
  selectedStudents,
  student,
  selectStudent,
  selectedStudentNotes,
  selectStudentNotes,
  editStudentSubNoteState,
}: {
  selectedStudents: StudentType[] | StudentInNoteType[];
  student: StudentType;
  selectStudent: (student: StudentType) => void;
  selectedStudentNotes: StudentType | null;
  selectStudentNotes: (student: StudentType) => void;
  editStudentSubNoteState?: StudentNoteType | null;
}) => {
  const {
    isLoading: isLoadingStudentPhoto,
    data: studentPhotoData,
    error: studentPhotoError,
  } = useQuery({
    queryKey: [student?.id, "photo"],
    queryFn: async () => {
      const req = await axios.get(`${BASEURL}/v1/photos`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          student: student?.id,
        },
      });

      return req.data;
    },
    retry: 0,
  });

  return (
    <div
      key={student?.id}
      className={classNames(
        clsx(
          "relative group flex cursor-pointer w-[96%] max-h-[10rem] h-[8rem] md:w-[94%] self-center justify-self-center items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-2 shadow-sm hover:border-gray-400",
          selectedStudents.find(
            (studentSelected) => studentSelected.id === student.id
          ) && "border-indigo-600 ring-indigo-500 ring-offset-2 ring-2"
        )
      )}
      onClick={() => selectStudent(student)}
    >
      <div className="flex-1">
        {isLoadingStudentPhoto || studentPhotoError || !studentPhotoData ? (
          <UserCircleIcon className="w-8 h-8 text-gray-400" />
        ) : (
          <img
            src={studentPhotoData.url}
            alt={student.first_name}
            className="object-cover w-8 h-8 rounded-full"
          />
        )}
        <p
          className={classNames(
            clsx(
              "text-sm font-medium text-gray-900",
              student.id === editStudentSubNoteState?.studentId &&
                "text-fuchsia-600"
            )
          )}
        >
          {student.first_name} {student.last_name}
        </p>
        <p className="text-sm text-gray-500 truncate">{GRADES_OPTIONS.filter((x) => x.value === student.grade)[0]?.name ?? "Unknown Grade"}</p>
        <p
          className={classNames(
            clsx(
              "absolute px-2 py-1 text-xs border-2 border-indigo-600 bg-white text-indigo-600 hover:bg-indigo-50 rounded-full cursor-pointer bottom-2 right-2"
            )
          )}
        >
          {student._count.students_on_notes}
        </p>
        <InformationCircleIcon
          className={classNames(
            clsx(
              "absolute h-6 text-xs cursor-pointer top-2 right-2 bg-white",
              selectedStudentNotes?.id === student.id
                ? "text-indigo-500 hover:text-indigo-700"
                : "hidden group-hover:block text-indigo-400 hover:text-indigo-500"
            )
          )}
          onClick={(event) => {
            event.stopPropagation();
            selectStudentNotes(student);
          }}
        />
      </div>
    </div>
  );
};
