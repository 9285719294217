import { useNavigate } from "react-router-dom";

export const useServiceLogin = () => {
  const navigate = useNavigate();

  const login = (token: string) => {
    localStorage.setItem("access_token", token);
    navigate("/dashboard");
  };

  return login;
};

export const useServiceLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("access_token");
    navigate("/");
  };

  return logout;
};
