import { ActionType } from "../../../types/home/action";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import clsx from "clsx";
import { useServiceLogout } from "../../../helpers/auth";
import { StudentInNoteType } from "../../../types/notes";
import { StudentType } from "../../../types/student";
import axios from "axios";
import { BASEURL } from "../../../constants";

const ACTIONS: ActionType[] = [
  {
    name: "Edit Groups",
    value: "editGroups",
  },
  {
    name: "Add Student",
    value: "addStudent",
  },
];

export const Actions = ({
  selectedStudents,
  selectedAction,
  selectAction,
  updateStudents,
}: {
  selectedStudents: StudentType[] | StudentInNoteType[];
  selectedAction: ActionType;
  selectAction: (action: ActionType) => void;
  updateStudents: () => void;
}) => {
  const logout = useServiceLogout();

  const handleArchiveStudents = async () => {
    const studentIds = selectedStudents.map(({ id }) => id);

    await axios.post(
      `${BASEURL}/v1/students/archive`,
      { students: studentIds },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    updateStudents();
  };

  return (
    <div className="flex items-center justify-between w-full gap-6">
      <div className="flex items-center gap-4">
        <img src="logo.png" alt="logo" className="w-52" />
        {ACTIONS.map((action: ActionType) => (
          <button
            key={action.name}
            type="button"
            className={classNames(
              clsx(
                "px-4 py-2 text-sm font-semibold rounded-md shadow-sm ring-1 ring-inset",
                action.value === selectedAction.value
                  ? "bg-indigo-600 text-white hover:bg-indigo-700 ring-indigo-500"
                  : "bg-white text-gray-900 hover:bg-gray-50 ring-gray-300"
              )
            )}
            onClick={() => selectAction(action)}
          >
            {action.name}
          </button>
        ))}
        <button
          type="button"
          className={classNames(
            clsx(
              "px-4 py-2 text-sm font-semibold rounded-md shadow-sm ring-1 ring-inset",
              selectedStudents.length === 0
                ? "bg-white text-gray-300 ring-gray-300 cursor-not-allowed"
                : "bg-red-600 text-white hover:bg-red-700 ring-red-500"
            )
          )}
          disabled={selectedStudents.length === 0}
          onClick={handleArchiveStudents}
        >
          Archive Students
        </button>
      </div>
      <button
        type="button"
        className={classNames(
          clsx(
            "px-4 py-2 text-sm font-semibold rounded-md shadow-sm ring-1 ring-inset flex",
            "bg-white text-gray-900 hover:bg-gray-50 ring-gray-300"
          )
        )}
        onClick={logout}
      >
        Logout
        <ArrowRightOnRectangleIcon className="w-5 h-5 ml-2" />
      </button>
    </div>
  );
};
