import { ChangeEvent, FormEvent, useState } from "react";
import { EyeIcon, EyeSlashIcon, UserIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

import { BASEURL } from "../../constants";
import { GoogleLogin } from "@react-oauth/google";
import { InputsData } from "../../types/login/index";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useServiceLogin } from "../../helpers/auth";

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputsData, setInputsData] = useState<InputsData>({
    username: "",
    password: "",
  });

  const login = useServiceLogin();

  const handlePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInputsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const loginMutation = useMutation(
    ({ username, password }: { username: string; password: string }) =>
      axios.post(`${BASEURL}/auth/login`, { username, password }),
    {
      onSuccess: (res) => {
        login(res.data.access_token);
      },

      onError: (error: any) => {
        alert(error.response?.data.message);
      }
    }
  );

  const googleLoginMutation = useMutation(
    ({ credential }: { credential: string }) =>
      axios.post(`${BASEURL}/auth/google`, { credential: credential }),
    {
      onSuccess: (res) => {
        login(res.data.access_token);
      },
    }
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!loginMutation.isLoading) {
      loginMutation.mutate(inputsData);
    }
  };

  const handleGoogleLogin = async (response: any) => {
    if (!googleLoginMutation.isLoading) {
      googleLoginMutation.mutate({ credential: response.credential });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div className="bg-[#E8ECFE] w-[90%] lg:max-w-4xl xl:max-w-6xl xl:max-h-[50rem] px-6 md:w-2/3 h-[70vh] lg:h-[60vh] flex flex-col gap-6 justify-center items-center rounded-lg">
        <div className="w-full max-w-md space-y-8 rounded-lg">
          <div>
            <h1 className="mt-6 text-6xl font-extrabold text-center text-gray-900">
              DocIt
            </h1>
            <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="flex justify-center">
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => console.log("error")}
              />
            </div>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="space-y-6 rounded-md shadow-sm">
              <div>
                <div className="relative rounded-md shadow-sm">
                  <label htmlFor="username" className="sr-only">
                    Username
                  </label>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    required
                    value={inputsData.username}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    placeholder="Username"
                  />
                  <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none right-2">
                    <UserIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="relative rounded-md shadow-sm">
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    value={inputsData.password}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    placeholder="Password"
                  />
                  <div
                    className="absolute inset-y-0 flex items-center pl-3 cursor-pointer right-2"
                    onClick={handlePasswordVisibility}
                  >
                    {showPassword ? (
                      <EyeSlashIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <EyeIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end">
              {/* <div className="flex items-center cursor-pointer">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="flex items-center justify-center w-4 h-4 border rounded-full"
                  defaultValue="true"
                />
                <label
                  htmlFor="remember_me"
                  className="block ml-2 text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div> */}
              <div className="text-sm">
                <Link
                  to="/forgot-password"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={loginMutation.isLoading}
              >
                Log in
              </button>
            </div>
          </form>
        </div>
        <div className="flex justify-center w-full">
          <h3 className="text-sm">
            No account?{" "}
            <a href="/register" className="text-blue-600 hover:text-blue-500">
              Register
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};
