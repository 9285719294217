import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import DatePicker, { CalendarContainer } from "react-datepicker";
import {
  Dispatch,
  LegacyRef,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  forwardRef,
} from "react";

import classNames from "classnames";
import clsx from "clsx";
import moment from "moment";

const DatePickerCustomInput = forwardRef(
  (
    {
      startDate,
      endDate,
      onClick,
      calendarOpen,
      showAllNotes,
    }: {
      startDate: Date | null;
      endDate: Date | null;
      onClick: () => void;
      calendarOpen: boolean;
      showAllNotes: boolean;
    },
    ref
  ) => (
    <div
      className="flex items-center justify-between gap-2 text-sm font-semibold leading-6 text-gray-900 pointer-events-none w-60 max-w-[15rem] min-w-[15rem]"
      onClick={onClick}
      ref={ref as LegacyRef<HTMLDivElement> | undefined}
    >
      {showAllNotes ? (
        <p className="cursor-auto">All Dates</p>
      ) : (
        <p onClick={(e) => e.stopPropagation()} className="cursor-auto">
          {moment(startDate).format("MM/DD/YYYY")} -{" "}
          {endDate ? moment(endDate).format("MM/DD/YYYY") : "Select"}
        </p>
      )}
      {calendarOpen ? (
        <ChevronUpIcon className="inline-block w-5 h-5 ml-1 text-gray-500 cursor-pointer pointer-events-auto" />
      ) : (
        <ChevronDownIcon className="inline-block w-5 h-5 ml-1 text-gray-500 cursor-pointer pointer-events-auto" />
      )}
    </div>
  )
);

const DatePickerContainer = ({
  className,
  children,
  viewMonth,
  handleViewMonth,
  showAllNotes,
  handleShowAllNotes,
}: {
  className?: string;
  children?: ReactNode;
  viewMonth: boolean;
  handleViewMonth: () => void;
  showAllNotes: boolean;
  handleShowAllNotes: () => void;
}) => {
  return (
    <div className="p-4 bg-[#E8ECFE] [&>*]:bg-[#E8ECFE] [&>*]:border-none rounded-md">
      <CalendarContainer className={className}>
        <div className="grid items-center justify-center w-full h-24">
          <button
            className={classNames(
              clsx(
                "text-sm font-semibold leading-6 cursor-pointer py-1 px-4 rounded-lg border border-solid border-gray-300",
                showAllNotes
                  ? "text-white bg-indigo-600 hover:bg-indigo-500"
                  : "text-gray-900 bg-white hover:bg-gray-100"
              )
            )}
            onClick={handleShowAllNotes}
          >
            All Dates
          </button>
          <button
            className={classNames(
              clsx(
                "text-sm font-semibold leading-6 cursor-pointer py-1 px-4 rounded-lg border border-solid border-gray-300",
                viewMonth
                  ? "text-white bg-indigo-600 hover:bg-indigo-500"
                  : "text-gray-900 bg-white hover:bg-gray-100"
              )
            )}
            onClick={handleViewMonth}
          >
            Select Month
          </button>
        </div>
        <div className="relative [&>*]:bg-[#E8ECFE] [&>*]:border-none">
          {children}
        </div>
      </CalendarContainer>
    </div>
  );
};

export const CustomDatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  viewMonth,
  setViewMonth,
  handleViewMonth,
  calendarOpen,
  setCalendarOpen,
  showAllNotes,
  handleShowAllNotes,
}: {
  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  viewMonth: boolean;
  setViewMonth: Dispatch<SetStateAction<boolean>>;
  handleViewMonth: () => void;
  calendarOpen: boolean;
  setCalendarOpen: Dispatch<SetStateAction<boolean>>;
  showAllNotes: boolean;
  handleShowAllNotes: () => void;
}) => {
  const handleDateChange = (
    dates: [Date | null, Date | null],
    event: SyntheticEvent<any, Event> | undefined
  ) => {
    if (viewMonth) setViewMonth(false);

    const [start, end] = dates;
    setStartDate(start);

    setEndDate(end);
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleDateChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      closeOnScroll={true}
      dateFormat="MMM d, eeee"
      calendarContainer={(props) => (
        <DatePickerContainer
          {...props}
          viewMonth={viewMonth}
          handleViewMonth={handleViewMonth}
          showAllNotes={showAllNotes}
          handleShowAllNotes={handleShowAllNotes}
        />
      )}
      todayButton="Today"
      customInput={
        <DatePickerCustomInput
          startDate={startDate}
          endDate={endDate}
          onClick={() => null}
          calendarOpen={calendarOpen}
          showAllNotes={showAllNotes}
        />
      }
      onCalendarOpen={() => setCalendarOpen(true)}
      onCalendarClose={() => setCalendarOpen(false)}
    />
  );
};
