import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { Dashboard } from "./views/dashboard";
import { ForgotPassword } from "./views/forgotPassword";
import { GuardedRoute } from "./guardedRoute";
import { Layout } from "./views/layout";
import { Login } from "./views/login";
import { NotFound } from "./views/404";
import { Registration } from "./views/registration";
import { ResetPassword } from "./views/resetPassword";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* here the unprotected routes go */}
        <Route
          element={
            <GuardedRoute routeGuarded={false} redirectRoute="/dashboard" />
          }
        >
          <Route path="/" element={<Login />} />
          <Route path="register" element={<Registration />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="test" element={<p> Test </p>} />
        </Route>

        {/* here the protected routes go */}
        <Route element={<GuardedRoute routeGuarded={true} redirectRoute="/" />}>
          <Route path="dashboard" element={<Dashboard />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
