import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  CreateNoteApiType,
  CreateNoteStateType,
  EditNoteType,
  NewNoteType,
  NoteType,
  StudentInNoteType,
  StudentNoteType,
  UpdateSubNoteType,
} from "../../../../types/notes";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { BASEURL } from "../../../../constants";
import Clock from "react-live-clock";
import { CreateNotePanel } from "./createNotePanel";
import { EditNotePanel } from "./editNotePanel";
import { EditStudentSubNotePanel } from "./editStudentSubNotePanel";
import ReactModal from "react-modal";
import { StudentType } from "../../../../types/student";
import axios from "axios";
import moment from "moment";

function HandleActionNotePanel({
  editStudentSubNoteState,
  studentSubNote,
  editNoteDateSelected,
  setEditNoteDateSelected,
  handleCreateNoteStateChange,
  handleCloseCreateOrEditNote,
  createNoteError,
  editNoteState,
  selectedNotes,
  openCreateNote,
  createNoteState,
  selectedStudents,
  openModal,
  handleNextStepNote,
  clickedTime,
  handleOpenCreateNote,
}: {
  editStudentSubNoteState: StudentNoteType | null;
  studentSubNote: StudentNoteType | null;
  editNoteDateSelected: Date | null;
  setEditNoteDateSelected: Dispatch<SetStateAction<Date | null>>;
  handleCreateNoteStateChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    name: string
  ) => void;
  handleCloseCreateOrEditNote: () => void;
  createNoteError: string | null;
  editNoteState: boolean;
  selectedNotes: NewNoteType[];
  openCreateNote: boolean;
  createNoteState: CreateNoteStateType;
  selectedStudents: StudentType[] | StudentInNoteType[];
  openModal: () => void;
  handleNextStepNote: () => void;
  clickedTime: string | null;
  handleOpenCreateNote: () => void;
}) {
  if (editStudentSubNoteState) {
    return (
      <EditStudentSubNotePanel
        studentSubNote={editStudentSubNoteState}
        createNoteState={createNoteState}
        editNoteDateSelected={editNoteDateSelected}
        setEditNoteDateSelected={setEditNoteDateSelected}
        handleCreateNoteStateChange={handleCreateNoteStateChange}
        handleCloseCreateOrEditNote={handleCloseCreateOrEditNote}
        createNoteError={createNoteError}
        handleNextStepNote={handleNextStepNote}
      />
    );
  } else if (editNoteState && selectedNotes.length > 0 && openCreateNote) {
    return (
      <EditNotePanel
        createNoteState={createNoteState}
        editNoteDateSelected={editNoteDateSelected}
        setEditNoteDateSelected={setEditNoteDateSelected}
        handleCreateNoteStateChange={handleCreateNoteStateChange}
        handleCloseCreateOrEditNote={handleCloseCreateOrEditNote}
        selectedStudents={selectedStudents}
        openModal={openModal}
        createNoteError={createNoteError}
        handleNextStepNote={handleNextStepNote}
      />
    );
  } else if (openCreateNote) {
    return (
      <CreateNotePanel
        createNoteState={createNoteState}
        clickedTime={clickedTime}
        handleCreateNoteStateChange={handleCreateNoteStateChange}
        handleCloseCreateOrEditNote={handleCloseCreateOrEditNote}
        selectedStudents={selectedStudents}
        createNoteError={createNoteError}
        handleNextStepNote={handleNextStepNote}
      />
    );
  } else {
    return (
      <>
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <Clock format={"hh:mm"} ticking={true} />
        </div>
        <button
          onClick={handleOpenCreateNote}
          className="block w-full text-start rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          {editNoteState && selectedNotes.length > 0
            ? "Edit note..."
            : "Add a note..."}
        </button>
      </>
    );
  }
}

export const NoteActionsPanel = ({
  selectedStudents,
  selectStudent,
  editNoteState,
  selectedNotes,
  openCreateNote,
  setOpenCreateNote,
  selectNote,
  editStudentSubNoteState,
  handleEditStudentSubNoteState,
}: {
  selectedStudents: StudentType[] | StudentInNoteType[];
  editNoteState: boolean;
  selectedNotes: NewNoteType[];
  openCreateNote: boolean;
  setOpenCreateNote: Dispatch<SetStateAction<boolean>>;
  selectNote: (note: NewNoteType | undefined) => void;
  editStudentSubNoteState: StudentNoteType | null;
  handleEditStudentSubNoteState: (studentNote: StudentNoteType | null) => void;
  selectStudent: (students: StudentType[] | StudentInNoteType[] | undefined) => void;
}) => {
  const [clickedTime, setClickedTime] = useState<string | null>(null);
  const [createNoteState, setCreateNoteState] = useState<CreateNoteStateType>({
    category: "other",
    subject: "",
    body: "",
    completed: false,
    task: false,
  });
  const [createNoteError, setCreateNoteError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editNoteDateSelected, setEditNoteDateSelected] = useState<Date | null>(
    null
  );

  const queryClient = useQueryClient();

  function handleOpenCreateNote() {
    setClickedTime(moment().format("hh:mm")); // <--- Save the current time
    setOpenCreateNote(true);
  }

  function handleCreateNoteStateChange(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    name: string
  ) {
    if (name === "completed" || name === "task") {
      return setCreateNoteState({
        ...createNoteState,
        [name]: (e.target as HTMLInputElement).checked,
      });
    }

    setCreateNoteState({
      ...createNoteState,
      [name]: e.target.value,
    });
  }

  useEffect(() => {
    setCreateNoteState({
      category: "other",
      subject: "",
      body: "",
      completed: false,
      task: false,
    });

    if (editStudentSubNoteState) {
      return setCreateNoteState({
        category: editStudentSubNoteState.category,
        subject: editStudentSubNoteState.subject,
        body: editStudentSubNoteState.body,
        completed: false,
        task: false,
      });
    }

    if (editNoteState && selectedNotes.length > 0) {
      const note = selectedNotes[selectedNotes.length - 1];
      return setCreateNoteState({
        category: note.category,
        subject: note.subject,
        body: note.body,
        completed: note.completed,
        task: note.task,
      });
    }
  }, [openCreateNote, selectedNotes, editNoteState, editStudentSubNoteState]);

  const createNoteMutation = useMutation(
    ({ note }: { note: CreateNoteApiType }) =>
      axios.post(
        `${BASEURL}/v1/notes`,
        {
          ...note,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["students"] });
        queryClient.invalidateQueries({ queryKey: ["notes"] });
      },
      onError: (error: {
        response: { data: { message: string; statusCode: number } };
      }) => {
        setCreateNoteError(error.response.data.message);
      },
    }
  );

  const editNoteMutation = useMutation(
    ({ note, noteId }: { note: EditNoteType; noteId: string }) =>
      axios.patch(
        `${BASEURL}/v1/notes/${noteId}`,
        {
          ...note,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ),
    {
      onSuccess: (data, {note}) => {
        queryClient.invalidateQueries({ queryKey: ["students"] });
        queryClient.invalidateQueries({ queryKey: ["notes"] });

        selectStudent(note.students);
      },
      onError: (error: {
        response: { data: { message: string; statusCode: number } };
      }) => {
        setCreateNoteError(error.response.data.message);
      },
    }
  );

  const updateSubNoteMutation = useMutation(
    ({
      note,
      noteId,
      studentId,
    }: {
      note: UpdateSubNoteType;
      noteId: string;
      studentId: string;
    }) =>
      axios.patch(
        `${BASEURL}/v1/subnotes/${noteId}/${studentId}`,
        {
          ...note,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["notes"] });
      },
      onError: (error: {
        response: { data: { message: string; statusCode: number } };
      }) => {
        setCreateNoteError(error.response.data.message);
      },
    }
  );

  const deleteNoteMutation = useMutation(
    ({ noteId }: { noteId: string }) =>
      axios.delete(`${BASEURL}/v1/notes/${noteId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
    {
      onSuccess: () => {
        closeModal();
        queryClient.invalidateQueries({ queryKey: ["students"] });
        queryClient.invalidateQueries({ queryKey: ["notes"] });
      },
    }
  );

  function handleNextStepNote() {
    if (editStudentSubNoteState) {
      const note: UpdateSubNoteType = {
        subject: createNoteState.subject,
        body: createNoteState.body,
        category: createNoteState.category,
        created_at: editNoteDateSelected,
      };

      selectNote(undefined);
      handleEditStudentSubNoteState(null);
      setOpenCreateNote(false);
      return updateSubNoteMutation.mutate({
        note,
        noteId: editStudentSubNoteState.noteId,
        studentId: editStudentSubNoteState.studentId,
      });
    } else if (selectedNotes.length > 0 && editNoteState) {
      const note = {
        ...createNoteState,
        students: selectedStudents,
        completed: `${createNoteState.completed}`,
        task: `${createNoteState.task}`,
        created_at: editNoteDateSelected,
      };
      const noteId = selectedNotes[selectedNotes.length - 1].id;
      editNoteMutation.mutate({ note, noteId });
      selectNote(undefined);
      return setOpenCreateNote(false);
    } else {
      const note = {
        ...createNoteState,
        students: selectedStudents,
        completed: `${createNoteState.completed}`,
        task: `${createNoteState.task}`,
        created_at: moment().toDate(),
      };
      createNoteMutation.mutate({ note });
      // selectNote(undefined);
      return setOpenCreateNote(false);
    }
  }

  function handleDeleteNote() {
    const noteId = selectedNotes[selectedNotes.length - 1].id;
    selectNote(undefined);
    deleteNoteMutation.mutate({ noteId });
    return setOpenCreateNote(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function handleCloseCreateOrEditNote() {
    if (selectedNotes.length > 0) {
      selectNote(selectedNotes[selectedNotes.length - 1]);
    }

    if (editStudentSubNoteState) {
      handleEditStudentSubNoteState(null);
    }

    setOpenCreateNote(false);
  }

  useEffect(() => {
    if (selectedNotes.length > 0) {
      const note = selectedNotes[selectedNotes.length - 1];
      setEditNoteDateSelected(moment(note.created_at).toDate());
    }

    if (editStudentSubNoteState) {
      setEditNoteDateSelected(
        moment(editStudentSubNoteState.created_at).toDate()
      );
    }
  }, [selectedNotes, editStudentSubNoteState]);

  useEffect(() => {
    setCreateNoteError(null);
  }, [openCreateNote]);

  return (
    <div className="relative border border-gray-200 rounded-md h-min">
      <HandleActionNotePanel
        studentSubNote={editStudentSubNoteState}
        editStudentSubNoteState={editStudentSubNoteState}
        editNoteDateSelected={editNoteDateSelected}
        setEditNoteDateSelected={setEditNoteDateSelected}
        handleCreateNoteStateChange={handleCreateNoteStateChange}
        handleCloseCreateOrEditNote={handleCloseCreateOrEditNote}
        createNoteError={createNoteError}
        editNoteState={editNoteState}
        selectedNotes={selectedNotes}
        openCreateNote={openCreateNote}
        createNoteState={createNoteState}
        selectedStudents={selectedStudents}
        openModal={openModal}
        handleNextStepNote={handleNextStepNote}
        clickedTime={clickedTime}
        handleOpenCreateNote={handleOpenCreateNote}
      />
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="absolute flex flex-col items-center justify-center w-1/2 p-4 overflow-hidden text-center -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-300 rounded-lg shadow-lg outline-none top-1/2 left-1/2 h-1/2 md:w-1/3 md:h-1/3"
      >
        <h2 className="mb-4 text-lg font-medium text-gray-900">
          Confirm Deletion
        </h2>
        <p className="text-sm text-gray-500">
          Are you sure you want to delete this note?
        </p>
        <div className="flex items-center justify-center w-full gap-12 mt-4">
          <button
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-500"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-500"
            onClick={handleDeleteNote}
          >
            Confirm
          </button>
        </div>
      </ReactModal>
    </div>
  );
};
