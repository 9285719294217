import { EditGroupsStateType, GroupType } from "../../../types/groups";
import {
  NewNoteType,
  StudentInNoteType,
  StudentNoteType,
} from "../../../types/notes";
import { ActionType } from "../../../types/home/action";
import { Actions } from "./actions";
import { EditGroupsLayout } from "./editGroups/editGroupsLayout";
import { LeftBlock } from "./leftBlock/leftBlock";
import { RightBlock } from "./rightBlock/rightBlock";
import { StudentType } from "../../../types/student";
import { useState } from "react";

export const SORT_BY_OPTIONS = [
  { label: "In Group", value: "in_group" },
  { label: "Not In Group", value: "not_in_group" },
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Notes", value: "note_count" },
];

export const RosterLayout = () => {
  const [selectedAction, setSelectedAction] = useState<ActionType>({
    name: "",
    value: "",
  });
  const [editNoteState, setEditNoteState] = useState(true);
  const [selectedStudents, setSelectedStudents] = useState<
    StudentType[] | StudentInNoteType[]
  >([]);
  const [selectedNotes, setSelectedNotes] = useState<NewNoteType[]>([]);
  const [openCreateNote, setOpenCreateNote] = useState<boolean>(false);
  const [selectedStudentNotes, setSelectedStudentNotes] =
    useState<StudentType | null>(null);
  const [editGroupState, setEditGroupState] = useState<EditGroupsStateType>({
    groupSelected: null,
    groupStudents: [],
    selectedGroupStudents: [],
    groupStudentsSortBy: SORT_BY_OPTIONS[0].value,
    groupStudentsSearch: "",
  });
  const [editStudentSubNoteState, setEditStudentSubNoteState] =
    useState<StudentNoteType | null>(null);
  const [refecthStudents, setRefetchStudents] = useState(0);

  function selectAction(action: ActionType) {
    if (selectedAction.value === action.value) {
      return setSelectedAction({ name: "", value: "" });
    }

    if (action.value === "studentDetails") {
      setSelectedStudents((prevState) => {
        if (prevState.length === 0) {
          return [];
        }

        return [prevState[prevState.length - 1]];
      });
    }

    return setSelectedAction(action);
  }

  function selectStudent(student: StudentType | StudentType[] | StudentInNoteType[] | undefined) {
    if (!student) {
      return setSelectedStudents([]);
    }

    if (Array.isArray(student)) {
      return setSelectedStudents(student);
    }

    if (selectedStudents.find((s) => s.id === student.id)) {
      return setSelectedStudents((prev) =>
        prev.filter((p) => p.id !== student.id)
      );
    }

    if (
      selectedAction.value === "studentDetails" ||
      selectedStudentNotes?.id === student.id
    ) {
      return setSelectedStudents(() => [student]);
    }

    if (!selectedStudentNotes) {
      return setSelectedStudents((prev) => [...prev, student]);
    }
  }

  function switchEditNoteState() {
    setEditNoteState((prev) => !prev);

    if (selectedNotes.length > 0) {
      if (editNoteState) {
        setOpenCreateNote(false);
      } else {
        setOpenCreateNote(true);
      }
    }

    if (selectedNotes.length > 0 && !editNoteState) {
      setSelectedStudents(
        selectedNotes[selectedNotes.length - 1].students_on_notes.map(
          (s) => s.student
        )
      );
    }

    setSelectedNotes((prev) =>
      prev.length > 0 ? [prev[prev.length - 1]] : []
    );
  }

  function selectNote(note: NewNoteType | undefined) {
    if (note) {
      if (selectedNotes.find((n) => n.id === note.id)) {
        if (editNoteState) {
          setOpenCreateNote(false);
        }

        return setSelectedNotes((prev) => prev.filter((p) => p.id !== note.id));
      }

      if (editNoteState) {
        setSelectedStudents(note.students_on_notes.map((s) => s.student));
        setOpenCreateNote(true);
        setSelectedStudentNotes(null);
        return setSelectedNotes((prev) => [...prev, note]);
      }

      return setSelectedNotes((prev) => [...prev, note]);
    } else {
      if (editNoteState) {
        setOpenCreateNote(false);
      }

      selectStudent(undefined);
      return setSelectedNotes([]);
    }
  }

  function selectStudentNotes(student: StudentType) {
    if (selectedStudentNotes?.id === student.id) {
      setSelectedStudents([]);
      setSelectedStudentNotes(null);
      return;
    }

    setSelectedNotes([]);
    setSelectedStudents([student]);
    setSelectedStudentNotes(student);
    return;
  }

  function handleSelectEditGroupState(group: GroupType, updateUsers?: boolean) {
    if (editGroupState.groupSelected?.id !== group.id) {
      return setEditGroupState({
        ...editGroupState,
        groupSelected: group,
        selectedGroupStudents: group.students
      });
    }

    if (editGroupState.groupSelected?.id === group.id && !updateUsers) {
      return setEditGroupState((prev) => ({
        ...prev,
        groupSelected: null,
        selectedGroupStudents: [],
      }));
    }
  }

  function handleSelectEditGroupStudents(student: StudentType) {
    if (editGroupState.selectedGroupStudents.find((s) => s.id === student.id)) {
      return setEditGroupState((prev) => ({
        ...prev,
        selectedGroupStudents: prev.selectedGroupStudents.filter(
          (s) => s.id !== student.id
        ),
      }));
    }

    return setEditGroupState((prev) => ({
      ...prev,
      selectedGroupStudents: [...prev.selectedGroupStudents, student],
    }));
  }

  function handleEditGroupStateChange(name: string, value: string | any[]) {
    setEditGroupState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function handleEditStudentSubNoteState(studentNote: StudentNoteType | null) {
    if (studentNote) {
      if (editStudentSubNoteState?.studentId === studentNote.studentId) {
        return setEditStudentSubNoteState(null);
      }

      return setEditStudentSubNoteState(studentNote);
    }

    return setEditStudentSubNoteState(null);
  }

  function handleArchiveStudents() {
    setSelectedStudents([]);
    setSelectedNotes([]);
    setRefetchStudents((current) => current + 1);
  }

  return (
    <div className="grid w-5/6 gap-10 h-5/6 max-w-screen-2xl auto-rows-min">
      <Actions
        selectedAction={selectedAction}
        selectAction={selectAction}
        selectedStudents={selectedStudents}
        updateStudents={handleArchiveStudents}
      />
      {selectedAction.value === "editGroups" ? (
        <EditGroupsLayout
          editGroupState={editGroupState}
          handleSelectEditGroupState={handleSelectEditGroupState}
          handleSelectEditGroupStudents={handleSelectEditGroupStudents}
          handleEditGroupStateChange={handleEditGroupStateChange}
          selectAction={selectAction}
        />
      ) : (
        <div className="grid gap-6 md:flex">
          <LeftBlock
            selectedStudents={selectedStudents}
            selectStudent={selectStudent}
            selectedStudentNotes={selectedStudentNotes}
            selectStudentNotes={selectStudentNotes}
            editStudentSubNoteState={editStudentSubNoteState}
            refetchStudents={refecthStudents}
          />
          <RightBlock
            selectedStudents={selectedStudents}
            selectStudent={selectStudent}
            selectedNotes={selectedNotes}
            selectNote={selectNote}
            selectedAction={selectedAction}
            selectAction={selectAction}
            editNoteState={editNoteState}
            switchEditNoteState={switchEditNoteState}
            openCreateNote={openCreateNote}
            setOpenCreateNote={setOpenCreateNote}
            selectedStudentNotes={selectedStudentNotes}
            editStudentSubNoteState={editStudentSubNoteState}
            handleEditStudentSubNoteState={handleEditStudentSubNoteState}
          />
        </div>
      )}
    </div>
  );
};
