import {
  Document,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import { NewNoteType, StudentInNoteType } from "../../../../types/notes";

import moment from "moment";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    backgroundColor: "#fff",
    textAlign: "justify",
    textIndent: 30,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },
  body: {
    fontSize: 14,
    marginBottom: 30,
  },
  checkbox: {
    flexDirection: "row",
    marginBottom: 20,
  },
  students: {
    fontSize: 12,
  },
  studentItem: {
    fontSize: 12,
    marginBottom: 10,
  },
  academic: {
    backgroundColor: "#f0e167",
  },
  behavioral: {
    backgroundColor: "#d94848",
  },
  parent_contact: {
    backgroundColor: "#8bcf48",
  },
  other: {
    backgroundColor: "#598cc2",
  },
  taskIndicator: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  subNote: {
    border: "4px",
    borderStyle: "solid",
    borderColor: "#000",
    padding: 10,
    marginTop: 10,
  },
});

const ReturnBg = (category: string) => {
  if (category === "academic") {
    return styles.academic;
  } else if (category === "behavioral") {
    return styles.behavioral;
  } else if (category === "parent_contact") {
    return styles.parent_contact;
  } else {
    return styles.other;
  }
};

export const PDFComponent = ({
  notes,
  studentFullName,
  student,
}: {
  notes: NewNoteType[];
  studentFullName: string;
  student: StudentInNoteType;
}) => {
  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>{studentFullName}</Text>
        {notes.map((note) => (
          <View key={note.id} style={[styles.section, ReturnBg(note.category)]}>
            <Text style={styles.title}>{note.subject}</Text>
            <Text style={styles.body}>
              Date: {moment(note.created_at).format("MM/DD/YYYY")}
            </Text>
            <Text style={styles.body}>Body: {note.body}</Text>
            {note.task && (
              <Text style={styles.body}>
                Done: {note.completed ? "True" : "False"}
              </Text>
            )}
            <SubNoteComponent note={note} student={student} />
          </View>
        ))}
      </Page>
    </Document>
  );
};

const SubNoteComponent = ({
  note,
  student,
}: {
  note: NewNoteType;
  student: StudentInNoteType;
}) => {
  const subNote = note.students_on_notes.find(
    (studentOnNote) => studentOnNote.studentId === student.id
  );

  if (subNote?.body || subNote?.subject) {
    return (
      <View style={[styles.subNote, ReturnBg(subNote.category)]}>
        <Text style={styles.title}>{subNote.subject}</Text>
        <Text style={styles.body}>
          {moment(subNote.created_at).format("MM/DD/YYYY")}
        </Text>
        <Text style={styles.body}>Body: {subNote.body}</Text>
      </View>
    );
  }

  return null;
};
