import { ChangeEvent, Dispatch, SetStateAction } from "react";
import {
  CreateNoteStateType,
  NewNoteType,
  StudentInNoteType,
  StudentNoteType,
} from "../../../../types/notes";

import { StudentType } from "../../../../types/student";
import moment from "moment";

export const CreateNotePanel = ({
  createNoteState,
  clickedTime,
  handleCreateNoteStateChange,
  handleCloseCreateOrEditNote,
  selectedStudents,
  createNoteError,
  handleNextStepNote,
}: {
  createNoteState: CreateNoteStateType;
  clickedTime: string | null;
  handleCreateNoteStateChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    name: string
  ) => void;
  handleCloseCreateOrEditNote: () => void;
  selectedStudents: StudentType[] | StudentInNoteType[];
  createNoteError: string | null;
  handleNextStepNote: () => void;
}) => {
  return (
    <div className="grid items-center grid-cols-5 gap-4 px-4 py-6 text-sm">
      <p className="text-sm text-gray-500">{clickedTime}</p>
      <div className="flex items-center col-span-3 gap-4">
        <label htmlFor="type">Select Type:</label>
        <select
          id="type"
          placeholder="Select a category"
          className="rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={createNoteState.category}
          onChange={(e) => handleCreateNoteStateChange(e, "category")}
        >
          <option value="other">Other</option>
          <option value="academic">Academic</option>
          <option value="behavioral">Behavioral</option>
          <option value="parent_contact">Parent Contact</option>
        </select>
      </div>
      <button
        onClick={handleCloseCreateOrEditNote}
        className="px-4 py-2 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm ring-red-500 ring-1 ring-inset"
      >
        Cancel
      </button>
      <p className="text-end">Students:</p>
      <p className="col-span-4">
        {selectedStudents.map((student) => student.first_name).join(", ")}
      </p>
      <label htmlFor="subject" className="text-end">
        Subject:
      </label>
      <input
        id="subject"
        name="subject"
        type="text"
        autoComplete="subject"
        required
        className="block col-span-4 w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={createNoteState.subject}
        onChange={(e) => handleCreateNoteStateChange(e, "subject")}
      />
      <label htmlFor="body" className="self-start text-end">
        Body:
      </label>
      <textarea
        id="body"
        name="body"
        rows={2}
        required
        className="block col-span-4 w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={createNoteState.body}
        onChange={(e) => handleCreateNoteStateChange(e, "body")}
      />

      {createNoteError && (
        <div className="col-span-5">
          <p className="text-xs text-center text-red-500">*{createNoteError}</p>
        </div>
      )}
      <div className="flex items-center col-span-2 gap-2">
        <input
          type="checkbox"
          name="todo"
          id="todo"
          checked={createNoteState.task}
          onChange={(e) => handleCreateNoteStateChange(e, "task")}
        />
        <label htmlFor="todo" className="cursor-pointer">
          Mark as To Do
        </label>
      </div>
      <div className="flex items-center col-span-2 gap-2">
        <input
          type="checkbox"
          name="done"
          id="done"
          checked={createNoteState.completed}
          onChange={(e) => handleCreateNoteStateChange(e, "completed")}
        />
        <label htmlFor="done" className="cursor-pointer">
          Mark Done
        </label>
      </div>
      <button
        onClick={handleNextStepNote}
        className="w-24 px-4 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm justify-self-end hover:bg-indigo-700 ring-indigo-500 ring-1 ring-inset"
      >
        Create
      </button>
    </div>
  );
};
