import {
  NewNoteType,
  StudentInNoteType,
  StudentNoteType,
  SubNoteType,
} from "../../../../types/notes";

import { ReturnBackgroundBasedOnNoteCategory } from "./note";
import { StudentType } from "../../../../types/student";
import classNames from "classnames";
import clsx from "clsx";
import moment from "moment";

export const SubNoteDecideWhatToRender = ({
  subNoteData,
  selectedStudents,
  selectedNotes,
  notesData,
  handleEditStudentSubNoteState,
}: {
  subNoteData: StudentNoteType[] | null;
  selectedStudents: StudentType[] | StudentInNoteType[];
  selectedNotes: NewNoteType[];
  notesData: NewNoteType[] | undefined;
  handleEditStudentSubNoteState: (note: StudentNoteType | null) => void;
}) => {
  if (!subNoteData) return null;

  return (
    <>
      {subNoteData.map((subNote) => {
        const mainNoteOfSubNote = notesData?.find(
          (note) => {
            return( note && note.id === subNote.noteId );
          }
        );

        const parentNoteLabel = mainNoteOfSubNote?.subject;

        return (
          <SubNote
            key={subNote.noteId + subNote.studentId}
            subNote={subNote}
            parentNoteLabel={parentNoteLabel}
            handleEditStudentSubNoteState={handleEditStudentSubNoteState}
          />
        );
      })}
    </>
  );
};

const SubNote = ({
  subNote,
  parentNoteLabel,
  handleEditStudentSubNoteState,
}: {
  subNote: StudentNoteType;
  parentNoteLabel: string | undefined;
  handleEditStudentSubNoteState: (note: StudentNoteType | null) => void;
}) => {
  if (!subNote.body && !subNote.subject) return null;

  const datePart = moment(subNote.created_at).format("MM/DD/YYYY");

  return (
    <div
      key={subNote.noteId + subNote.studentId}
      className={classNames(
        clsx(
          "p-4 border border-solid max-w-[300px] max-h-min rounded-lg flex flex-col gap-1 cursor-pointer hover:shadow-lg",
          ReturnBackgroundBasedOnNoteCategory(subNote.category)
        )
      )}
      onClick={() => handleEditStudentSubNoteState(subNote)}
    >
      <p className="text-lg leading-5 text-black">
        Main Note: {parentNoteLabel}
      </p>
      <p className="text-base leading-5 text-black">
        {subNote.student.first_name} {subNote.student.last_name} Sub Note
      </p>
      <p className="text-xs leading-5 text-gray-500">{datePart}</p>
      <h3 className="text-sm font-semibold leading-6 text-gray-900">
        {subNote.subject}
      </h3>
      <p className="text-sm leading-5 text-gray-500">{subNote.body}</p>
    </div>
  );
};
