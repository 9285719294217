import {
  NewNoteType,
  SelectNoteType,
  StudentNoteType,
} from "../../../../types/notes";

import { MouseEvent } from "react";
import classNames from "classnames";
import clsx from "clsx";
import moment from "moment";

export const NoteDecideWhatToRender = ({
  isLoadingNotes,
  notesError,
  notesData,
  startDate,
  endDate,
  viewMonth,
  selectedNotes,
  selectNote,
  editStudentSubNoteState,
  handleEditStudentSubNoteState,
}: {
  isLoadingNotes: boolean;
  notesError: any;
  notesData: NewNoteType[] | undefined;
  startDate: Date | null;
  endDate?: Date | null;
  viewMonth: boolean;
  selectedNotes: NewNoteType[];
  selectNote: SelectNoteType;
  editStudentSubNoteState: StudentNoteType | null;
  handleEditStudentSubNoteState: (note: StudentNoteType | null) => void;
}) => {
  if (isLoadingNotes) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        Loading...
      </div>
    );
  } else if (notesError) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        Error
      </div>
    );
  } else if (notesData?.length === 0) {
    return (
      <NoNotes startDate={startDate} viewMonth={viewMonth} endDate={endDate} />
    );
  } else {
    return (
      <Notes
        notes={notesData}
        selectedNotes={selectedNotes}
        selectNote={selectNote}
        editStudentSubNoteState={editStudentSubNoteState}
        handleEditStudentSubNoteState={handleEditStudentSubNoteState}
      />
    );
  }
};

const NoNotes = ({
  startDate,
  viewMonth,
  endDate,
}: {
  startDate: Date | null;
  viewMonth: boolean;
  endDate?: Date | null;
}) => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      No notes for{" "}
      {viewMonth
        ? moment(startDate).format("MM-YYYY")
        : moment(startDate).format("MM/DD/YYYY")}
      {endDate && !viewMonth && " "}
      {endDate && !viewMonth && "-"}
      {endDate && !viewMonth && " "}
      {endDate && !viewMonth && moment(endDate).format("MM/DD/YYYY")}
    </div>
  );
};

export const ReturnBackgroundBasedOnNoteCategory = (category: string) => {
  if (category === "academic") {
    return "bg-yellow-100";
  } else if (category === "behavioral") {
    return "bg-red-100";
  } else if (category === "parent_contact") {
    return "bg-green-100";
  } else {
    return "bg-blue-100";
  }
};

const ReturnBorderColor = (
  category: string,
  note: NewNoteType,
  selectedNotes: NewNoteType[]
) => {
  if (note.id === selectedNotes[selectedNotes.length - 1].id) {
    if (category === "academic") {
      return "border-yellow-600 hover:border-yellow-300";
    }
    if (category === "behavioral") {
      return "border-red-600 hover:border-red-300";
    }
    if (category === "parent_contact") {
      return "border-green-600 hover:border-green-300";
    }

    return "border-blue-800 hover:border-blue-300";
  } else {
    return "border-blue-600 hover:border-blue-300";
  }
};

const Notes = ({
  notes,
  selectedNotes,
  selectNote,
  editStudentSubNoteState,
  handleEditStudentSubNoteState,
}: {
  notes: NewNoteType[] | undefined;
  selectedNotes: NewNoteType[];
  selectNote: SelectNoteType;
  editStudentSubNoteState: StudentNoteType | null;
  handleEditStudentSubNoteState: (note: StudentNoteType | null) => void;
}) => {
  function handleStudentNameClick(
    e: MouseEvent<HTMLButtonElement>,
    student: StudentNoteType
  ) {
    e.stopPropagation();

    handleEditStudentSubNoteState(student);
  }

  // sort notes desc
  notes?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

  return (
    <>
      {notes?.map((note) => {
        if( !note) return;
        const splitDate = note.created_at.split("T");
        const datePart = moment(splitDate[0]).format("MM/DD/YYYY");

        return (
          <div
            key={note.id}
            className={classNames(
              clsx(
                "p-4 border-4 border-solid max-w-[300px] max-h-min rounded-lg cursor-pointer hover:shadow-lg grid grid-rows-[auto,auto,auto,1fr]",
                ReturnBackgroundBasedOnNoteCategory(note.category),
                selectedNotes.find(
                  (selectedNote) => selectedNote.id === note.id
                ) && ReturnBorderColor(note.category, note, selectedNotes)
              )
            )}
            onClick={() => selectNote(note)}
          >
            <p className="text-xs leading-5 text-gray-500">{datePart}</p>
            <h3 className="text-sm font-semibold leading-6 text-gray-900">
              {note.subject}
            </h3>
            <p className="text-sm leading-5 text-gray-500">{note.body}</p>
            <div className="grid self-end gap-1 h-min">
              {note.students_on_notes.map((studentNote) => {
                return (
                  <button
                    className={classNames(
                      clsx(
                        "text-xs leading-5 cursor-pointer justify-self-start",
                        studentNote.noteId ===
                          editStudentSubNoteState?.noteId &&
                          studentNote.studentId ===
                            editStudentSubNoteState?.studentId
                          ? "text-fuchsia-500 hover:text-fuchsia-700"
                          : "text-gray-500 hover:text-gray-900"
                      )
                    )}
                    key={studentNote.studentId}
                    onClick={(e) => handleStudentNameClick(e, studentNote)}
                  >
                    - {studentNote.student.first_name}{" "}
                    {studentNote.student.last_name}
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
