import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { BASEURL } from "../../../../constants";
import { DynamicallyRenderStudents } from "../dynamicallyRenderStudents";
import { EditGroupsStateType } from "../../../../types/groups";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import ReactModal from "react-modal";
import { SORT_BY_OPTIONS } from "../layout";
import { StudentCard } from "../leftBlock/studentCard";
import { StudentType } from "../../../../types/student";
import axios from "axios";
import classNames from "classnames";
import clsx from "clsx";
import { debounce } from "lodash";

const OPTIONS = [
  {
    id: 1,
    name: "Save Changes",
    value: "save_changes",
  },
  {
    id: 2,
    name: "Cancel Changes",
    value: "cancel_changes",
  },
  {
    id: 3,
    name: "Rename Group",
    value: "rename_group",
  },
  {
    id: 4,
    name: "Delete Group",
    value: "delete_group",
  },
];

export const RightBlock = ({
  editGroupState,
  handleSelectEditGroupStudents,
  handleEditGroupStateChange,
  handleSelectEditGroupState,
}: {
  editGroupState: EditGroupsStateType;
  handleSelectEditGroupStudents: (student: StudentType) => void;
  handleEditGroupStateChange: (name: string, value: string | any[]) => void;
  handleSelectEditGroupState: (group: any, updateUsers?: boolean) => void;
}) => {
  const [actionsState, setActionsState] = useState<{
    loading: boolean;
    error: string;
    success: boolean;
  }>({
    loading: false,
    error: "",
    success: false,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");

  const queryClient = useQueryClient();

  const {
    data: studentsInGroupData,
    isLoading: isLoadingStudentsInGroup,
    error: studentsInGroupError,
  } = useQuery(
    [
      "studentsInGroup",
      editGroupState.groupSelected,
      editGroupState.groupStudentsSortBy,
      editGroupState.groupStudentsSearch,
    ],
    async () => {
      const { data } = await axios.get(`${BASEURL}/v1/students`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          ...(editGroupState.groupSelected && {
            group: editGroupState.groupSelected.id,
          }),
          ...(editGroupState.groupStudentsSortBy !== "in_group" &&
            editGroupState.groupStudentsSortBy !== "not_in_group" && {
              sort_by: editGroupState.groupStudentsSortBy,
            }),
          ...(editGroupState.groupStudentsSearch && {
            search: editGroupState.groupStudentsSearch,
          }),
        },
      });
      return data;
    }
  );

  const {
    data: studentsNotInGroupData,
    isLoading: isLoadingStudentsNotInGroup,
    error: studentsNotInGroupError,
  } = useQuery(
    [
      "studentsNotInGroup",
      editGroupState.groupSelected,
      editGroupState.groupStudentsSortBy,
      editGroupState.groupStudentsSearch,
    ],
    async () => {
      if (
        editGroupState.groupSelected &&
        (editGroupState.groupStudentsSortBy === "in_group" ||
          editGroupState.groupStudentsSortBy === "not_in_group")
      ) {
        const { data } = await axios.get(`${BASEURL}/v1/students`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          params: {
            ...(editGroupState.groupSelected && {
              not_group: editGroupState.groupSelected.id,
            }),
            ...(editGroupState.groupStudentsSearch && {
              search: editGroupState.groupStudentsSearch,
            }),
          },
        });
        return data;
      } else {
        return [];
      }
    }
  );

  const updateGroupMutation = useMutation(
    ({
      id,
      name,
      students,
    }: {
      id: string;
      name: string;
      students: StudentType[];
    }) =>
      axios.patch(
        `${BASEURL}/v1/groups/${id}`,
        {
          name,
          students: students.map((student) => student.id),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["groups"] });
        queryClient.invalidateQueries({ queryKey: ["studentsInGroup"] });
        queryClient.invalidateQueries({ queryKey: ["studentsNotInGroup"] });
        setActionsState({ ...actionsState, loading: false, success: true });
        setNewGroupName(""); // reset the group name state
        setRenameModalOpen(false); // close the rename modal
      },
    }
  );

  const deleteGroupMutation = useMutation(
    (id: string) =>
      axios.delete(`${BASEURL}/v1/groups/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["groups"] });
        setModalOpen(false);
      },
    }
  );

  useEffect(() => {
    if (studentsInGroupData) {
      if (
        editGroupState.groupSelected &&
        editGroupState.groupStudentsSortBy === "in_group"
      ) {
        if (studentsNotInGroupData) {
          handleEditGroupStateChange("groupStudents", [
            ...studentsInGroupData,
            ...studentsNotInGroupData,
          ]);
        }
      } else if (
        editGroupState.groupSelected &&
        editGroupState.groupStudentsSortBy === "not_in_group"
      ) {
        if (studentsNotInGroupData) {
          handleEditGroupStateChange("groupStudents", [
            ...studentsNotInGroupData,
            ...studentsInGroupData,
          ]);
        }
      } else {
        handleEditGroupStateChange("groupStudents", studentsInGroupData);
      }
    }
  }, [studentsInGroupData, studentsNotInGroupData]);

  const debouncedSearch = debounce((value) => {
    handleEditGroupStateChange("groupStudentsSearch", value);
  }, 800);

  function changeSearch(e: ChangeEvent<HTMLInputElement>) {
    debouncedSearch(e.target.value);
  }

  function handleActionChange(value: string) {
    if (
      value === "save_changes" &&
      editGroupState.groupSelected &&
      !actionsState.loading
    ) {
      setActionsState({ ...actionsState, loading: true });
      updateGroupMutation.mutate({
        id: editGroupState.groupSelected.id,
        name: editGroupState.groupSelected.name,
        students: editGroupState.selectedGroupStudents,
      });
    }

    if (value === "cancel_changes") {
      handleEditGroupStateChange(
        "selectedGroupStudents",
        editGroupState.groupSelected?.students ?? []
      );
    }

    if (value === "rename_group") {
      if (editGroupState.groupSelected) {
        setRenameModalOpen(true); // open the rename modal
        setNewGroupName(editGroupState.groupSelected.name); // set the initial group name
      }
    }

    if (
      value === "delete_group" &&
      editGroupState.groupSelected &&
      !isModalOpen
    ) {
      setModalOpen(true);
    }
  }

  function closeModal() {
    setModalOpen(false);
  }

  function modalConfirmDelete() {
    if (editGroupState.groupSelected) {
      handleSelectEditGroupState(editGroupState.groupSelected);
      deleteGroupMutation.mutate(editGroupState.groupSelected.id);
    }
  }

  function handleChangeGroupName(e: ChangeEvent<HTMLInputElement>) {
    setNewGroupName(e.target.value);
  }

  function handleRenameGroup() {
    if (editGroupState.groupSelected && newGroupName.trim() !== "") {
      updateGroupMutation.mutate({
        id: editGroupState.groupSelected.id,
        name: newGroupName,
        students: editGroupState.selectedGroupStudents,
      });
    }
  }

  return (
    <div className="grid grid-rows-[auto,1fr,auto] max-h-[calc(80vh-3rem)] gap-4">
      <div className="grid items-center w-full gap-4 text-sm md:justify-between md:flex">
        <div className="flex items-center justify-between w-full gap-4 text-sm md:justify-start">
          <label htmlFor="sort">Sort By:</label>
          <select
            name="sort"
            className="rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(e) =>
              handleEditGroupStateChange("groupStudentsSortBy", e.target.value)
            }
            value={editGroupState.groupStudentsSortBy}
          >
            {SORT_BY_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className={classNames(clsx("flex cursor-pointer items-center"))}>
          <div className="relative flex items-center w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-2 cursor-pointer">
              <MagnifyingGlassIcon className="w-5 h-5" />
            </div>
            <input
              type="text"
              name="search"
              id="search"
              className="w-full px-2 py-1 pl-8 border-gray-300 rounded-lg border-1 focus:ring-1 focus:ring-indigo-600"
              onChange={changeSearch}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(min(150px,100%),1fr))] auto-rows-max gap-4 overflow-y-auto w-full py-1">
        <DynamicallyRenderStudents
          loading={isLoadingStudentsInGroup || isLoadingStudentsNotInGroup}
          loadingElement={
            <div className="flex items-center justify-center w-full h-full">
              <p>Loading...</p>
            </div>
          }
          isError={studentsInGroupError || studentsNotInGroupError}
          errorElement={
            <div className="flex items-center justify-center w-full h-full">
              <p>Error</p>
            </div>
          }
          noStudents={editGroupState.groupStudents.length === 0}
          noStudentsElement={
            <div className="flex items-center justify-center w-full h-full">
              <p>No students found</p>
            </div>
          }
          studentsElements={editGroupState.groupStudents.map((student) => (
            <StudentCard
              key={student.id}
              selectedStudents={editGroupState.selectedGroupStudents}
              student={student}
              selectStudent={handleSelectEditGroupStudents}
              selectedStudentNotes={null}
              selectStudentNotes={() => {}}
            />
          ))}
        />
      </div>

      <div className="grid w-full h-full grid-cols-2 gap-4 md:items-center md:justify-around md:flex">
        {OPTIONS.map((option) => (
          <div key={option.id} className="w-full">
            <button
              className={classNames(
                clsx(
                  "w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-500",
                  !editGroupState.groupSelected &&
                    "opacity-50 cursor-not-allowed"
                )
              )}
              onClick={() => handleActionChange(option.value)}
              disabled={!editGroupState.groupSelected}
            >
              {option.name}
            </button>
          </div>
        ))}
      </div>

      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        className="absolute flex flex-col items-center justify-center w-1/2 p-4 overflow-hidden text-center -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-300 rounded-lg shadow-lg outline-none top-1/2 left-1/2 h-1/2 md:w-1/3 md:h-1/3"
      >
        <h2 className="mb-4 text-lg font-medium text-gray-900">
          Confirm Deletion
        </h2>
        <p className="text-sm text-gray-500">
          Are you sure you want to delete this group?
        </p>
        <div className="flex items-center justify-center w-full gap-12 mt-4">
          <button
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-500"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-500"
            onClick={modalConfirmDelete}
          >
            Confirm
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isRenameModalOpen}
        onRequestClose={() => setRenameModalOpen(false)}
        className="absolute flex flex-col items-center justify-center w-1/2 p-4 overflow-hidden text-center -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-300 rounded-lg shadow-lg outline-none top-1/2 left-1/2 h-1/2 md:w-1/3 md:h-1/3"
      >
        <h2 className="mb-4 text-lg font-medium text-gray-900">Rename Group</h2>
        <input
          type="text"
          value={newGroupName}
          onChange={handleChangeGroupName}
          className="w-3/4 px-2 py-1 border-gray-300 rounded-lg border-1 focus:ring-1 focus:ring-indigo-600"
        />
        <div className="flex items-center justify-center w-full gap-12 mt-4">
          <button
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-500"
            onClick={() => setRenameModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className={classNames(
              clsx(
                "px-4 py-2 text-sm font-medium text-white rounded-md",
                newGroupName.trim() === ""
                  ? "opacity-50 cursor-not-allowed bg-gray-300 hover:bg-gray-400"
                  : "bg-green-400 hover:bg-green-500"
              )
            )}
            onClick={handleRenameGroup}
            disabled={newGroupName.trim() === ""}
          >
            Save
          </button>
        </div>
      </ReactModal>
    </div>
  );
};
