import "react-datepicker/dist/react-datepicker.css";

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  NewNoteType,
  SelectNoteType,
  StudentInNoteType,
  StudentNoteType,
} from "../../../../types/notes";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { ActionType } from "../../../../types/home/action";
import { AddStudentPanel } from "./addStudentPanel";
import { BASEURL } from "../../../../constants";
import { CustomDatePicker } from "./datePicker";
import { ExportButton } from "./exportButton";
import { NoteActionsPanel } from "./noteActionsPanel";
import { NoteDecideWhatToRender } from "./note";
import { StudentDetails } from "./studentDetails";
import { StudentType } from "../../../../types/student";
import { SubNoteDecideWhatToRender } from "./subNoteCard";
import axios from "axios";
import classNames from "classnames";
import clsx from "clsx";
import moment from "moment";

const FilterBy = [
  { label: "All", value: "" },
  { label: "Academic", value: "academic" },
  { label: "Behavioral", value: "behavioral" },
  { label: "Parent Contact", value: "parent_contact" },
  { label: "Other", value: "other" },
];

function HandlePanelDisplay({
  selectStudent,
  selectedAction,
  selectAction,
  selectedStudentNotes,
  selectedStudents,
  selectedNotes,
  selectNote,
  editStudentSubNoteState,
  handleEditStudentSubNoteState,
  changeFilterBy,
  actions,
  startDate,
  endDate,
  isLoadingNotes,
  notesError,
  notesData,
  viewMonth,
  editNoteState,
  openCreateNote,
  setOpenCreateNote,
  subNoteData,
}: {
  selectStudent: (student: StudentInNoteType[] | StudentType[] | undefined) => void;
  selectedAction: ActionType;
  selectAction: (action: ActionType) => void;
  selectedStudentNotes: StudentType | null;
  selectedStudents: StudentType[] | StudentInNoteType[];
  selectedNotes: NewNoteType[];
  selectNote: SelectNoteType;
  editStudentSubNoteState: StudentNoteType | null;
  handleEditStudentSubNoteState: (note: StudentNoteType | null) => void;
  changeFilterBy: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  actions: {
    filterBy: string;
  };
  startDate: Date | null;
  endDate: Date | null;
  isLoadingNotes: boolean;
  notesError: any;
  notesData: NewNoteType[] | undefined;
  viewMonth: boolean;
  editNoteState: boolean;
  openCreateNote: boolean;
  setOpenCreateNote: Dispatch<SetStateAction<boolean>>;
  subNoteData: StudentNoteType[] | null;
}) {
  if (selectedAction.value === "addStudent") {
    return <AddStudentPanel selectAction={selectAction} />;
  } else if (
    selectedAction.value === "studentDetails" ||
    selectedStudentNotes
  ) {
    return (
      <StudentDetails
        selectedStudents={selectedStudents}
        selectedStudentNotes={selectedStudentNotes}
        selectedNotes={selectedNotes}
        selectNote={selectNote}
        editStudentSubNoteState={editStudentSubNoteState}
        handleEditStudentSubNoteState={handleEditStudentSubNoteState}
      />
    );
  } else {
    return (
      <>
        <div className="flex flex-col justify-between w-full gap-2 md:flex-row">
          <div className="flex items-center justify-between gap-4 text-sm">
            <label htmlFor="filter">Filter By:</label>
            <select
              name="filter"
              onChange={changeFilterBy}
              value={actions.filterBy}
              className="rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              {FilterBy.map((filter) => (
                <option key={filter.value} value={filter.value}>
                  {filter.label}
                </option>
              ))}
            </select>
          </div>
          <ExportButton
            selectedNotes={selectedNotes}
            selectedStudents={selectedStudents}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <div className="grid gap-4 overflow-y-scroll w-full grid-cols-[repeat(auto-fit,minmax(min(200px,100%),1fr))]">
          <SubNoteDecideWhatToRender
            subNoteData={subNoteData}
            selectedStudents={selectedStudents}
            selectedNotes={selectedNotes}
            notesData={notesData}
            handleEditStudentSubNoteState={handleEditStudentSubNoteState}
          />
          <NoteDecideWhatToRender
            isLoadingNotes={isLoadingNotes}
            notesError={notesError}
            notesData={notesData}
            startDate={startDate}
            endDate={endDate}
            viewMonth={viewMonth}
            selectedNotes={selectedNotes}
            selectNote={selectNote}
            editStudentSubNoteState={editStudentSubNoteState}
            handleEditStudentSubNoteState={handleEditStudentSubNoteState}
          />
        </div>
        <NoteActionsPanel
          selectStudent={selectStudent}
          selectedStudents={selectedStudents}
          editNoteState={editNoteState}
          selectedNotes={selectedNotes}
          openCreateNote={openCreateNote}
          setOpenCreateNote={setOpenCreateNote}
          selectNote={selectNote}
          editStudentSubNoteState={editStudentSubNoteState}
          handleEditStudentSubNoteState={handleEditStudentSubNoteState}
        />
      </>
    );
  }
}

export const RightBlock = ({
  selectedStudents,
  selectedNotes,
  selectNote,
  selectedAction,
  selectAction,
  editNoteState,
  switchEditNoteState,
  openCreateNote,
  setOpenCreateNote,
  selectedStudentNotes,
  editStudentSubNoteState,
  handleEditStudentSubNoteState,
  selectStudent
}: {
  selectedStudents: StudentType[] | StudentInNoteType[];
  selectedNotes: NewNoteType[];
  selectNote: SelectNoteType;
  selectedAction: ActionType;
  selectAction: (action: ActionType) => void;
  editNoteState: boolean;
  switchEditNoteState: () => void;
  openCreateNote: boolean;
  setOpenCreateNote: Dispatch<SetStateAction<boolean>>;
  selectedStudentNotes: StudentType | null;
  editStudentSubNoteState: StudentNoteType | null;
  handleEditStudentSubNoteState: (note: StudentNoteType | null) => void;
  selectStudent: (student: StudentInNoteType[] | StudentType[] | undefined) => void;
}) => {
  const [startDate, setStartDate] = useState<Date | null>(moment().toDate());
  const [endDate, setEndDate] = useState<Date | null>(moment().toDate());
  const [viewMonth, setViewMonth] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [actions, setActions] = useState({
    filterBy: FilterBy[0].value,
  });
  const [subNotes, setSubNotes] = useState<StudentNoteType[] | null>(null);
  const [showAllNotes, setShowAllNotes] = useState(true);
  const lastFetchedNotes = useRef<NewNoteType[] | null>(null);
  const [filteredNotes, setFilteredNotes] = useState<NewNoteType[] | undefined>(
    undefined
  );

  const {
    data: notesData,
    isLoading: isLoadingNotes,
    error: notesError,
  }: UseQueryResult<NewNoteType[], any> = useQuery(
    [
      "notes",
      startDate,
      endDate,
      viewMonth,
      actions.filterBy,
      selectedStudents,
      showAllNotes,
    ],
    async (): Promise<NewNoteType[]> => {
      if (openCreateNote) return lastFetchedNotes.current!;

      const req = await axios.get(`${BASEURL}/v1/notes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          ...(!showAllNotes && { begin_date: startDate }),
          ...(!showAllNotes && { end_date: endDate }), // if showAllNotes is true, then don't send end_date (default is null
          ...(selectedStudents.length > 0 && {
            students: selectedStudents.map((student) => student.id).join(","),
          }),
          ...(actions.filterBy && { category: actions.filterBy }),
        },
      });

      return req.data;
    },
    {
      onSuccess: (notesData) => {
        lastFetchedNotes.current = notesData;
        setFilteredNotes(notesData);
      },
    }
  );

  function changeFilterBy(e: React.ChangeEvent<HTMLSelectElement>) {
    setActions((prev) => ({ ...prev, filterBy: e.target.value }));
  }

  function handleViewMonth() {
    setStartDate((prev) => moment(prev).startOf("month").toDate());
    setEndDate((prev) =>
      prev
        ? moment(prev).endOf("month").toDate()
        : moment(startDate).endOf("month").toDate()
    );

    setViewMonth((prev) => !prev);
  }

  function handleShowAllNotes() {
    setShowAllNotes((prev) => !prev);
  }

  useEffect(() => {
    if (
      selectedNotes.length === 0 &&
      selectedStudents.length > 0 &&
      notesData
    ) {
      const lastNote = notesData[notesData.length - 1];
      const lastSelectedStudent = selectedStudents[selectedStudents.length - 1];

      setSubNotes(
        lastNote?.students_on_notes?.filter(
          (studentOnNote) => studentOnNote.studentId === lastSelectedStudent.id
        )
      );
    } else if (selectedNotes.length > 0 && notesData) {
      const lastSelectedNote = selectedNotes[selectedNotes.length - 1];
      setSubNotes(lastSelectedNote.students_on_notes);
    } else {
      setSubNotes(null);
    }
  }, [selectedStudents, selectedNotes, notesData]);

  useEffect(() => {
    if (subNotes && subNotes.length > 0) {
      // order the notesData with the note of the subNote firs
      setFilteredNotes((prevSortedNotesData) => {
        const mainNoteOfSubNote = prevSortedNotesData?.find(
          (note) => note.id === subNotes[0].noteId
        );
        const notesWithoutMainNote = prevSortedNotesData?.filter(
          (note) => note.id !== subNotes[0].noteId
        );

        return [mainNoteOfSubNote!, ...notesWithoutMainNote!];
      });
    }

    if (!subNotes) {
      setFilteredNotes(lastFetchedNotes.current!);
    }
  }, [subNotes]);

  return (
    <div className="relative grid w-full max-h-[80vh] min-h-[80vh] gap-6 p-6 border-2 border-gray-300 border-solid rounded-lg grid-rows-[auto,1fr,auto]">
      <div
        className={classNames(
          clsx(
            "absolute z-50 flex gap-4 -top-5 left-1/2 -translate-x-1/2 w-max px-4 p-1 border-solid border-2 border-gray-300 rounded-lg bg-[#E8ECFE]"
          )
        )}
      >
        {selectedAction.name || selectedStudentNotes ? (
          <h3 className="text-lg font-semibold leading-6 text-gray-900">
            {selectedStudentNotes ? "Student Details" : selectedAction.name}
          </h3>
        ) : (
          <CustomDatePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            viewMonth={viewMonth}
            setViewMonth={setViewMonth}
            handleViewMonth={handleViewMonth}
            calendarOpen={calendarOpen}
            setCalendarOpen={setCalendarOpen}
            showAllNotes={showAllNotes}
            handleShowAllNotes={handleShowAllNotes}
          />
        )}
      </div>
      <HandlePanelDisplay
        selectStudent={selectStudent}
        selectedAction={selectedAction}
        selectAction={selectAction}
        selectedStudentNotes={selectedStudentNotes}
        selectedStudents={selectedStudents}
        selectedNotes={selectedNotes}
        selectNote={selectNote}
        editStudentSubNoteState={editStudentSubNoteState}
        handleEditStudentSubNoteState={handleEditStudentSubNoteState}
        changeFilterBy={changeFilterBy}
        actions={actions}
        startDate={startDate}
        endDate={endDate}
        isLoadingNotes={isLoadingNotes}
        notesError={notesError}
        notesData={filteredNotes}
        viewMonth={viewMonth}
        editNoteState={editNoteState}
        openCreateNote={openCreateNote}
        setOpenCreateNote={setOpenCreateNote}
        subNoteData={subNotes}
      />
    </div>
  );
};
