import { EditGroupsStateType, GroupType } from "../../../../types/groups";
import { PlusCircleIcon, UserGroupIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { BASEURL } from "../../../../constants";
import { DynamicallyRenderStudents } from "../dynamicallyRenderStudents";
import { StudentType } from "../../../../types/student";
import axios from "axios";
import classNames from "classnames";
import clsx from "clsx";

export const ListGroups = ({
  editGroupState,
  handleSelectEditGroupState,
}: {
  editGroupState: EditGroupsStateType;
  handleSelectEditGroupState: (group: GroupType, updateUsers?: boolean) => void;
}) => {
  const [createGroupState, setCreateGroupState] = useState<{
    open: boolean;
    name: string;
    creating: boolean;
    error: string;
    success: boolean;
  }>({
    open: false,
    name: "",
    creating: false,
    error: "",
    success: false,
  });

  const queryClient = useQueryClient();

  function handleCreateGroupStateChange(name: string, value: string | boolean) {
    setCreateGroupState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const {
    data: groupsData,
    isLoading: isLoadingGroups,
    error: groupsError,
  } = useQuery(["groups"], async () => {
    const { data } = await axios.get(`${BASEURL}/v1/groups`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return data;
  });

  const createGroupMutation = useMutation(
    ({ name, students }: { name: string; students: StudentType[] }) =>
      axios.post(
        `${BASEURL}/v1/groups`,
        {
          name: name,
          students: students.map((student) => student.id),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ),
    {
      onSuccess: () => {
        handleCreateGroupStateChange("success", true);
        handleCreateGroupStateChange("name", "");
        handleCreateGroupStateChange("open", false);
        handleCreateGroupStateChange("creating", false);
        handleCreateGroupStateChange("error", "");
        queryClient.invalidateQueries({ queryKey: ["groups"] });
      },
      onError: (error: {
        response: { data: { message: string; statusCode: number } };
      }) => {
        handleCreateGroupStateChange("creating", false);
        handleCreateGroupStateChange("error", error.response.data.message);
      },
    }
  );

  function switchOpenState() {
    if (createGroupState.creating) return;
    if (!createGroupState.open) {
      handleCreateGroupStateChange("success", false);
      handleCreateGroupStateChange("error", "");
    }

    handleCreateGroupStateChange("open", !createGroupState.open);
  }

  function handleCreateGroup() {
    handleCreateGroupStateChange("success", false);
    handleCreateGroupStateChange("error", "");

    if (!createGroupState.name)
      return handleCreateGroupStateChange("error", "Please enter a group name");
    if (!createGroupState.creating) {
      handleCreateGroupStateChange("creating", true);
      return createGroupMutation.mutate({
        name: createGroupState.name,
        students: editGroupState.selectedGroupStudents,
      });
    }
  }

  useEffect(() => {
    if (editGroupState.groupSelected && groupsData) {
      handleSelectEditGroupState(
        groupsData.find(
          (group: GroupType) => group.id === editGroupState.groupSelected?.id
        ),
        true
      );
    }
  }, [groupsData, editGroupState, handleSelectEditGroupState]);

  return (
    <div className="grid gap-4 max-h-[calc(80vh-3rem)] grid-rows-[1fr,auto]">
      <div className="grid w-full h-full gap-4 overflow-y-auto auto-rows-max">
        <DynamicallyRenderStudents
          loading={isLoadingGroups}
          loadingElement={
            <div className="flex items-center justify-center w-full h-full">
              <p>Loading...</p>
            </div>
          }
          isError={groupsError}
          errorElement={
            <div className="flex items-center justify-center w-full h-full">
              <p>Error</p>
            </div>
          }
          noStudents={groupsData.length === 0}
          noStudentsElement={
            <div className="flex items-center justify-center w-full h-full">
              <p>No groups found</p>
            </div>
          }
          studentsElements={groupsData.map((group: GroupType) => (
            <div
              key={group.id}
              className={classNames(
                clsx(
                  "flex items-center gap-2 w-full cursor-pointer py-2 px-4 h-min rounded-lg",
                  group.id === editGroupState.groupSelected?.id
                    ? "bg-indigo-600 text-white hover:bg-indigo-500"
                    : "bg-white text-gray-900 hover:bg-gray-50"
                )
              )}
              onClick={() => handleSelectEditGroupState(group)}
            >
              <UserGroupIcon className="h-8 text-gray-400" />
              <div>
                <p>{group.name}</p>
                <p className="text-xs">{group.students.length} Students</p>
              </div>
            </div>
          ))}
        />
      </div>

      <div className="grid gap-4 h-min">
        {createGroupState.success && (
          <p className="pl-2 text-xs text-green-600">
            Group Created Successfully
          </p>
        )}
        {createGroupState.error && (
          <p className="pl-2 text-xs text-red-600">{createGroupState.error}</p>
        )}
        {createGroupState.open && (
          <div
            className={classNames(clsx("grid gap-4 items-center w-full h-min"))}
          >
            <p className="pl-2 text-xs">
              Selected Students {editGroupState.selectedGroupStudents.length}
            </p>
            <div className="relative flex items-center w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-2">
                <UserGroupIcon className="h-8 text-gray-400" />
              </div>
              <input
                type="text"
                name="search"
                id="search"
                className="w-full px-4 py-2 pl-12 pr-10 border-gray-300 rounded-lg border-1 focus:ring-1 focus:ring-indigo-600"
                placeholder="Group Name"
                value={createGroupState.name}
                onChange={(e) =>
                  handleCreateGroupStateChange("name", e.target.value)
                }
              />

              <div onClick={switchOpenState} className="absolute inset-y-0 right-0 flex items-center pr-2 hover:cursor-pointer">
                <XCircleIcon className="h-6 text-red-400" />
              </div>
            </div>
          </div>
        )}
        {!createGroupState.open && !createGroupState.creating && (
          <div
            className="flex items-center gap-2 px-4 py-2 text-indigo-600 rounded-lg cursor-pointer h-min hover:border-indigo-600 hover:ring-indigo-500 ring-2"
            onClick={switchOpenState}
          >
            <PlusCircleIcon className="h-6 text-gray-400" />
            New Group
          </div>
        )}
        {createGroupState.open && (
          <div
            className="flex items-center gap-2 px-4 py-2 text-indigo-600 rounded-lg cursor-pointer h-min hover:border-green-600 hover:ring-green-500 ring-2"
            onClick={handleCreateGroup}
          >
            <PlusCircleIcon className="h-6 text-gray-400" />
            {createGroupState.creating ? "Creating Group..." : "Create Group"}
          </div>
        )}
      </div>
    </div>
  );
};
