import { EditGroupsStateType, GroupType } from "../../../../types/groups";

import { ActionType } from "../../../../types/home/action";
import { ListGroups } from "./listGroups";
import { RightBlock } from "./rightBlock";
import { StudentType } from "../../../../types/student";
import { XCircleIcon } from "@heroicons/react/24/solid";

export const EditGroupsLayout = ({
  editGroupState,
  handleSelectEditGroupState,
  handleSelectEditGroupStudents,
  handleEditGroupStateChange,
  selectAction,
}: {
  editGroupState: EditGroupsStateType;
  handleSelectEditGroupState: (group: GroupType) => void;
  handleSelectEditGroupStudents: (student: StudentType) => void;
  handleEditGroupStateChange: (name: string, value: string | any[]) => void;
  selectAction: (action: ActionType) => void;
}) => {
  return (
    <div className="relative grid w-full md:h-[80vh] gap-6 p-6 border-2 border-gray-300 border-solid rounded-lg md:grid-cols-[2fr,5fr] grid-rows-2 md:grid-rows-1">
      <div className="absolute flex justify-between items-center w-3/4 md:w-2/5 -top-5 left-6 px-4 p-1 border-solid border-2 border-gray-300 rounded-lg bg-[#E8ECFE]">
        <h3 className="text-lg font-semibold leading-6 text-gray-900">
          Edit Groups
        </h3>
        <XCircleIcon
          className="inline-block h-5 ml-1 text-gray-500 cursor-pointer"
          onClick={() => selectAction({ name: "", value: "" })}
        />
      </div>
      <ListGroups
        editGroupState={editGroupState}
        handleSelectEditGroupState={handleSelectEditGroupState}
      />

      <RightBlock
        editGroupState={editGroupState}
        handleSelectEditGroupStudents={handleSelectEditGroupStudents}
        handleEditGroupStateChange={handleEditGroupStateChange}
        handleSelectEditGroupState={handleSelectEditGroupState}
      />
    </div>
  );
};
