import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, UserIcon } from "@heroicons/react/24/solid";
import { RegisterData, ResetPasswordData } from "../../types/login/index";

import { AxiosError } from "../../types/registration.ts/index";
import { BASEURL } from "../../constants";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useQueryRouter } from "../../helpers/useQueryRouter";

export const ResetPassword = () => {
  const [showPasswords, setShowPasswords] = useState({
    password: false,
    confirmPassword: false,
  });
  const [inputsData, setInputsData] = useState<ResetPasswordData>({
    password: "",
    confirmPassword: "",
  });
  const [inputsErrors, setInputsErrors] = useState({
    error: false,
    message: "",
  });

  const navigate = useNavigate();
  const params = useQueryRouter();

  const handlePasswordVisibility = (name: "password" | "confirmPassword") => {
    setShowPasswords((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (inputsErrors.error) {
      setInputsErrors({
        error: false,
        message: "",
      });
    }

    setInputsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const mutation = useMutation(
    ({
      password,
      confirmPassword,
    }: {
      password: string;
      confirmPassword: string;
    }) =>
      axios.post(`${BASEURL}/auth/reset_password`, {
        password,
        confirmPassword,
        reset_token: params.get("token"),
      }),
    {
      onSuccess: (res) => {
        localStorage.setItem("access_token", res.data.access_token);
        navigate("/");
      },
    }
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Check if passwords match
    if (inputsData.password !== inputsData.confirmPassword) {
      setInputsErrors({
        error: true,
        message: "Passwords do not match",
      });
    }

    if (!mutation.isLoading && !inputsErrors.error) {
      mutation.mutate(inputsData);
    }
  };

  useEffect(() => {
    if (!params.get("token")) {
      // If there are no parameters
      navigate("/"); // Redirect to root
    }
  }, [params, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div className="bg-[#E8ECFE] w-[90%] lg:max-w-4xl xl:max-w-6xl xl:max-h-[50rem] px-6 md:w-2/3 h-[70vh] lg:h-[60vh] flex flex-col gap-6 justify-center items-center rounded-lg">
        <div className="w-full max-w-md space-y-8 rounded-lg">
          <div>
            <h1 className="mt-6 text-6xl font-extrabold text-center text-gray-900">
              DocIt
            </h1>
            <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
              Reset Password
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="space-y-6 rounded-md shadow-sm">
              <div>
                <div className="relative rounded-md shadow-sm">
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type={showPasswords.password ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    value={inputsData.password}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    placeholder="Password"
                  />
                  <div
                    className="absolute inset-y-0 flex items-center pl-3 cursor-pointer right-2"
                    onClick={() => handlePasswordVisibility("password")}
                  >
                    {showPasswords.password ? (
                      <EyeSlashIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <EyeIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="relative rounded-md shadow-sm">
                  <label htmlFor="confirmPassword" className="sr-only">
                    Confirm Password
                  </label>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showPasswords.confirmPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    value={inputsData.confirmPassword}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    placeholder="Confirm Password"
                  />
                  <div
                    className="absolute inset-y-0 flex items-center pl-3 cursor-pointer right-2"
                    onClick={() => handlePasswordVisibility("confirmPassword")}
                  >
                    {showPasswords.confirmPassword ? (
                      <EyeSlashIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <EyeIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {inputsErrors.error ? (
              <div className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-red-700 bg-red-100 rounded-md">
                {inputsErrors.message}
              </div>
            ) : mutation.isError ? (
              <div className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-red-700 bg-red-100 rounded-md">
                {mutation.error instanceof Error && "response" in mutation.error
                  ? (mutation.error as AxiosError).response.data.message
                  : null}
              </div>
            ) : null}
            <div>
              <button
                type="submit"
                className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={mutation.isLoading}
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
