import { ChangeEvent, FormEvent, useState } from "react";
import { EyeIcon, EyeSlashIcon, UserIcon } from "@heroicons/react/24/solid";

import { AxiosError } from "../../types/registration.ts/index";
import { BASEURL } from "../../constants";
import { ForgotPasswordData } from "../../types/login/index";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
  const [inputsData, setInputsData] = useState<ForgotPasswordData>({
    username: "",
  });
  const [inputsErrors, setInputsErrors] = useState({
    error: false,
    message: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (inputsErrors.error) {
      setInputsErrors({
        error: false,
        message: "",
      });
    }

    setInputsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const mutation = useMutation(
    ({ username }: { username: string }) =>
      axios.post(`${BASEURL}/auth/request_reset`, {
        email: username,
      }),
    {
      onSuccess: (res) => {
        navigate("/");
      },
    }
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!mutation.isLoading) {
      mutation.mutate(inputsData);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div className="bg-[#E8ECFE] w-[90%] lg:max-w-4xl xl:max-w-6xl xl:max-h-[50rem] px-6 md:w-2/3 h-[70vh] lg:h-[60vh] flex flex-col gap-6 justify-center items-center rounded-lg">
        <div className="w-full max-w-md space-y-8 rounded-lg">
          <div>
            <h1 className="mt-6 text-6xl font-extrabold text-center text-gray-900">
              DocIt
            </h1>
            <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
              Recover Password
            </h2>
            <p className="mt-2 text-sm text-center text-gray-600">
              If the username exists, an email will be sent to the registered
              email address with a link to reset your password.
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="space-y-6 rounded-md shadow-sm">
              <div>
                <div className="relative rounded-md shadow-sm">
                  <label htmlFor="username" className="sr-only">
                    Username
                  </label>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    required
                    value={inputsData.username}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    placeholder="Username"
                  />
                  <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none right-2">
                    <UserIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            {inputsErrors.error ? (
              <div className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-red-700 bg-red-100 rounded-md">
                {inputsErrors.message}
              </div>
            ) : mutation.isError ? (
              <div className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-red-700 bg-red-100 rounded-md">
                {mutation.error instanceof Error && "response" in mutation.error
                  ? (mutation.error as AxiosError).response.data.message
                  : null}
              </div>
            ) : null}
            <div>
              <button
                type="submit"
                className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled={mutation.isLoading}
              >
                Send Email
              </button>
            </div>
          </form>
        </div>
        <div className="flex justify-center w-full">
          <h3 className="text-sm">
            Or{" "}
            <a href="/" className="text-blue-600 hover:text-blue-500">
              Log in
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};
