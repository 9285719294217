import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef } from "react";
import { GroupMenuStateType, GroupType } from "../../../../types/groups";

import { BASEURL } from "../../../../constants";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export const GroupMenu = ({
  groupMenuData,
  closeGroupMenu,
  switchGroupMenu,
  selectGroup,
  setGroupsData,
}: {
  groupMenuData: GroupMenuStateType;
  closeGroupMenu: () => void;
  switchGroupMenu: () => void;
  selectGroup: (group: GroupType) => void;
  setGroupsData: (groups: GroupType[]) => void;
}) => {
  const {
    data: groupsData,
    isLoading: isLoadingGroups,
    error: groupsError,
  } = useQuery(["groups"], async () => {
    const { data } = await axios.get(`${BASEURL}/v1/groups`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return data;
  });

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeGroupMenu();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (groupsData) {
      setGroupsData(groupsData);
    }
  }, [groupsData]);

  return (
    <div
      ref={ref}
      className="absolute grid z-50 gap-2 w-3/4 md:w-1/2 -top-5 left-6 px-4 p-1 border-solid border-2 border-gray-300 rounded-lg bg-[#E8ECFE]"
    >
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={switchGroupMenu}
      >
        <h3 className="text-lg font-semibold leading-6 text-gray-900">
          {groupMenuData.selected.name}
        </h3>
        {groupMenuData.open ? (
          <ChevronUpIcon className="inline-block h-5 ml-1 text-gray-500" />
        ) : (
          <ChevronDownIcon className="inline-block h-5 ml-1 text-gray-500" />
        )}
      </div>
      {groupMenuData.open && (
        <ul className="grid gap-2">
          {isLoadingGroups ? (
            <li>
              <p>Loading...</p>
            </li>
          ) : groupsError ? (
            <li>
              <p>Error</p>
            </li>
          ) : (
            <>
              <li>
                <button
                  className="w-full text-left hover:underline underline-offset-4"
                  onClick={() =>
                    selectGroup({
                      id: "0",
                      name: "All",
                      userId: "",
                      students: [],
                    })
                  }
                >
                  All
                </button>
              </li>
              {groupMenuData.groups.map((group: GroupType) => {
                const { id, name, userId, students } = group;
                return (
                  <li key={id}>
                    <button
                      className="w-full text-left hover:underline underline-offset-4"
                      onClick={() =>
                        selectGroup({ id, name, userId, students })
                      }
                    >
                      {name}
                    </button>
                  </li>
                );
              })}
            </>
          )}
        </ul>
      )}
    </div>
  );
};
