import {
  AddStudentSectionsType,
  CreateStudentFormValidationType,
  CreateStudentType,
  GradesEnum,
  StudentGradeType,
} from "../../../../types/student";
import { ChangeEvent, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ActionType } from "../../../../types/home/action";
import { BASEURL } from "../../../../constants";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import classNames from "classnames";
import clsx from "clsx";

export const GRADES_OPTIONS: StudentGradeType[] = [
  {
    name: "Pre-K",
    value: GradesEnum.preK,
  },
  {
    name: "Kindergarten",
    value: GradesEnum.k,
  },
  {
    name: "First",
    value: GradesEnum.first,
  },
  {
    name: "Second",
    value: GradesEnum.second,
  },
  {
    name: "Third",
    value: GradesEnum.third,
  },
  {
    name: "Fourth",
    value: GradesEnum.fourth,
  },
  {
    name: "Fifth",
    value: GradesEnum.fifth,
  },
  {
    name: "Sixth",
    value: GradesEnum.sixth,
  },
  {
    name: "Seventh",
    value: GradesEnum.seventh,
  },
  {
    name: "Eighth",
    value: GradesEnum.eighth,
  },
  {
    name: "Nineth",
    value: GradesEnum.nineth,
  },
  {
    name: "Tenth",
    value: GradesEnum.tenth,
  },
  {
    name: "Eleventh",
    value: GradesEnum.eleventh,
  },
  {
    name: "Twelfth",
    value: GradesEnum.twelfth,
  },
];

const SECTIONS: AddStudentSectionsType[] = [
  {
    block: 1,
    children: [
      {
        name: "First Name",
        value: "first_name",
        type: "text",
      },
      {
        name: "Last Name",
        value: "last_name",
        type: "text",
      },
    ],
  },
  {
    block: 2,
    children: [
      {
        name: "Grade",
        value: "grade",
        type: "select",
        options: GRADES_OPTIONS,
      },
    ],
  },
  {
    block: 3,
    children: [
      {
        name: "Parent / Guardian 1",
        value: "guardian_a_full_name",
        type: "text",
      },
      {
        name: "Guardian Relationship",
        value: "guardian_a_relationship",
        type: "text",
      },
      {
        name: "Phone Number",
        value: "guardian_a_phone",
        type: "text",
      },
      {
        name: "Email",
        value: "guardian_a_email",
        type: "text",
      },
    ],
  },
  {
    block: 4,
    children: [
      {
        name: "Parent / Guardian 2",
        value: "guardian_b_full_name",
        type: "text",
      },
      {
        name: "Guardian Relationship",
        value: "guardian_b_relationship",
        type: "text",
      },
      {
        name: "Phone Number",
        value: "guardian_b_phone",
        type: "text",
      },
      {
        name: "Email",
        value: "guardian_b_email",
        type: "text",
      },
    ],
  },
];

const ACTIONS = [
  {
    name: "Save",
    value: "save",
  },
  {
    name: "Cancel",
    value: "cancel",
  },
  {
    name: "Import from Database",
    value: "import",
  },
];

export const AddStudentPanel = ({
  selectAction,
}: {
  selectAction: (action: ActionType) => void;
}) => {
  const [formValidation, setFormValidation] =
    useState<CreateStudentFormValidationType>({
      file: null,
      studentPicture: "",
      firstName: false,
      lastName: false,
      grade: false,
      guardianAFullName: false,
      guardianAPhone: false,
      guardianAEmail: false,
    });

  const [studentData, setStudentData] = useState<CreateStudentType>({
    first_name: "",
    last_name: "",
    grade: "",
    guardian_a_full_name: "",
    guardian_a_relationship: "",
    guardian_a_phone: "",
    guardian_a_email: "",
    guardian_b_full_name: "",
    guardian_b_relationship: "",
    guardian_b_phone: "",
    guardian_b_email: "",
  });

  const queryClient = useQueryClient();

  const addStudentMutation = useMutation(
    ({ studentData }: { studentData: CreateStudentType }) =>
      axios.post(
        `${BASEURL}/v1/students`,
        { ...studentData, grade: studentData.grade },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ),
    {
      onSuccess: (res) => {
        if (formValidation.studentPicture) {
          uploadStudentPictureMutation.mutate({
            file: formValidation.file,
            student: res.data.id,
          });
        } else {
          queryClient.invalidateQueries({ queryKey: ["students"] });
          resetForm();
        }
      },
    }
  );

  const uploadStudentPictureMutation = useMutation(
    ({ file, student }: { file: File | null; student: string }) => {
      const formData = new FormData();
      if (file) {
        formData.append("file", file);
      }
      formData.append("student", student);

      return axios.post(`${BASEURL}/v1/photos`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data", // specify the Content-Type header
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["students"] });
        resetForm();
      },
    }
  );

  function handleInputChange(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    key: keyof CreateStudentType
  ) {
    setStudentData((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  }

  function handleFormValidation(
    key: string,
    value: string | boolean | ArrayBuffer | File | null
  ) {
    setFormValidation((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  const handlePreview = (id: string, e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        handleFormValidation(id, reader.result);
        handleFormValidation("file", file);
      };
      reader.readAsDataURL(file);
    } else {
      handleFormValidation(id, "");
      handleFormValidation("file", null);
    }
  };

  function handleSubmission() {
    addStudentMutation.mutate({ studentData });
  }

  function resetForm() {
    setStudentData({
      first_name: "",
      last_name: "",
      grade: "",
      guardian_a_full_name: "",
      guardian_a_relationship: "",
      guardian_a_phone: "",
      guardian_a_email: "",
      guardian_b_full_name: "",
      guardian_b_relationship: "",
      guardian_b_phone: "",
      guardian_b_email: "",
    });
    setFormValidation({
      file: null,
      studentPicture: "",
      firstName: false,
      lastName: false,
      grade: false,
      guardianAFullName: false,
      guardianAPhone: false,
      guardianAEmail: false,
    });
  }

  return (
    <div className="grid w-full max-w-2xl row-span-3 gap-4 mx-auto overflow-y-scroll">
      <div className="flex flex-col items-center justify-center gap-4">
        <UserCircleIcon
          className={classNames(
            "w-16 h-16 text-gray-400",
            formValidation.studentPicture ? "hidden" : "block"
          )}
        />
        <img
          id="preview"
          className={classNames(
            "top-0 left-0 object-cover rounded-full w-16 h-16",
            formValidation.studentPicture ? "block" : "hidden"
          )}
          src={formValidation.studentPicture}
          alt="Profile Preview"
        />
        <input
          type="file"
          id="profile-pic"
          accept="image/*"
          className="hidden"
          onChange={(e) => handlePreview("studentPicture", e)}
        />
        <label
          htmlFor="profile-pic"
          className="absolute block w-16 h-16 cursor-pointer"
        ></label>
      </div>
      {SECTIONS.map((section) => (
        <div className="flex flex-col gap-4 h-min" key={section.block}>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {section.children.map((child, index) => (
              <div
                className={classNames(
                  clsx(
                    "grid gap-4",
                    index === section.children.length - 1 && index % 2 === 0
                      ? "md:col-span-2"
                      : ""
                  )
                )}
                key={child.value}
              >
                <h1 className="text-sm font-semibold text-gray-900">
                  {child.name}
                </h1>
                {child.type === "select" ? (
                  <select
                    className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md"
                    onChange={(event) =>
                      handleInputChange(
                        event,
                        child.value as keyof CreateStudentType
                      )
                    }
                    value={studentData[child.value as keyof CreateStudentType]}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {child.options &&
                      child.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="w-full px-4 py-2 text-sm border border-gray-300 rounded-md"
                    onChange={(event) =>
                      handleInputChange(
                        event,
                        child.value as keyof CreateStudentType
                      )
                    }
                    value={studentData[child.value as keyof CreateStudentType]}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ))}

      <div
        className={classNames(
          clsx("grid grid-cols-2 items-center justify-center gap-4 self-end")
        )}
      >
        {ACTIONS.map((action) => (
          <button
            key={action.name}
            type="button"
            className={classNames(
              clsx(
                "px-4 py-2 text-sm font-semibold rounded-md shadow-sm ring-1 ring-inset",
                action.value === "save"
                  ? "bg-indigo-600 text-white hover:bg-indigo-700 ring-indigo-500"
                  : action.value === "cancel"
                  ? "bg-red-600 text-white hover:bg-red-700 ring-red-500"
                  : "bg-white text-gray-900 hover:bg-gray-50 ring-gray-300",
                action.value === "import" && "col-span-2"
              )
            )}
            onClick={() => {
              if (action.value === "save") {
                handleSubmission();
              } else if (action.value === "cancel") {
                selectAction({ value: "Add Student", name: "" });
              }
            }}
          >
            {action.name}
          </button>
        ))}
      </div>
    </div>
  );
};
