export const DynamicallyRenderStudents = ({
  loading,
  loadingElement,
  isError,
  errorElement,
  noStudents,
  noStudentsElement,
  studentsElements,
}: {
  loading: boolean;
  loadingElement: JSX.Element;
  isError: unknown;
  errorElement: JSX.Element;
  noStudents: boolean;
  noStudentsElement: JSX.Element;
  studentsElements: JSX.Element[];
}) => {
  if (loading) {
    return loadingElement;
  }

  if (isError) {
    return errorElement;
  }

  if (noStudents) {
    return noStudentsElement;
  }

  return <>{studentsElements}</>;
};
