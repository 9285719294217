export type StudentType = {
  id: string;
  studentId?: string;
  first_name: string;
  last_name: string;
  grade: string;
  guardian_a_full_name: string;
  guardian_a_relationship: string;
  guardian_a_phone: string;
  guardian_a_email: string;
  guardian_b_full_name: string;
  guardian_b_relationship: string;
  guardian_b_phone: string;
  guardian_b_email: string;
  _count: {
    students_on_notes: number;
  };
};

export type CreateStudentType = Omit<StudentType, "id" | "_count" | "notes">;

export type CreateStudentFormValidationType = {
  file: File | null;
  studentPicture: string;
  firstName: boolean;
  lastName: boolean;
  grade: boolean;
  guardianAFullName: boolean;
  guardianAPhone: boolean;
  guardianAEmail: boolean;
};

export enum GradesEnum {
  preK = "preK",
  k="k",
  first = "first",
  second = "second",
  third = "third",
  fourth = "fourth",
  fifth = "fifth",
  sixth = "sixth",
  seventh = "seventh",
  eighth = "eighth",
  nineth = "nineth",
  tenth = "tenth",
  eleventh = "eleventh",
  twelfth = "twelfth",
}

export type StudentGradeType = {
  name: string;
  value: GradesEnum;
};

export type AddStudentSectionsType = {
  block: number;
  children: {
    name: string;
    value: string;
    type: string;
    options?: StudentGradeType[];
  }[];
};

export type EditStudentType = {
  [key: string]: string | GradesEnum | null;
  id: string;
  first_name: string;
  last_name: string;
  grade: GradesEnum | null;
  guardian_a_full_name: string;
  guardian_a_relationship: string;
  guardian_a_phone: string;
  guardian_a_email: string;
  guardian_b_full_name: string;
  guardian_b_relationship: string;
  guardian_b_phone: string;
  guardian_b_email: string;
};
