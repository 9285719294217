import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  CreateNoteStateType,
  NewNoteType,
  StudentNoteType,
} from "../../../../types/notes";

import { StudentType } from "../../../../types/student";
import moment from "moment";

export const EditStudentSubNotePanel = ({
  studentSubNote,
  createNoteState,
  editNoteDateSelected,
  setEditNoteDateSelected,
  handleCreateNoteStateChange,
  handleCloseCreateOrEditNote,
  createNoteError,
  handleNextStepNote,
}: {
  studentSubNote: StudentNoteType;
  createNoteState: CreateNoteStateType;
  editNoteDateSelected: Date | null;
  setEditNoteDateSelected: Dispatch<SetStateAction<Date | null>>;
  handleCreateNoteStateChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    name: string
  ) => void;
  handleCloseCreateOrEditNote: () => void;
  createNoteError: string | null;
  handleNextStepNote: () => void;
}) => {
  return (
    <>
      <div className="grid items-center grid-cols-5 gap-4 px-4 py-6 text-sm">
        <input
          type="date"
          name="datePicker"
          id="datePicker"
          className="w-full text-gray-900 border-0 rounded-md ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-xs sm:leading-6"
          onChange={(e) =>
            setEditNoteDateSelected(moment(e.target.value).toDate())
          }
          value={moment(editNoteDateSelected).format("YYYY-MM-DD")}
        />
        <div className="flex items-center col-span-3 gap-4">
          <label htmlFor="type">Select Type:</label>
          <select
            id="type"
            placeholder="Select a category"
            className="rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={createNoteState.category}
            onChange={(e) => handleCreateNoteStateChange(e, "category")}
          >
            <option value="other">Other</option>
            <option value="academic">Academic</option>
            <option value="behavioral">Behavioral</option>
            <option value="parent_contact">Parent Contact</option>
          </select>
        </div>
        <button
          onClick={handleCloseCreateOrEditNote}
          className="px-4 py-2 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm ring-red-500 ring-1 ring-inset"
        >
          Cancel
        </button>
        <p className="text-end">Student:</p>
        <p className="col-span-4 text-fuchsia-500">
          {studentSubNote.student.first_name} {studentSubNote.student.last_name}
        </p>
        <label htmlFor="subject" className="text-end">
          Subject:
        </label>
        <input
          id="subject"
          name="subject"
          type="text"
          autoComplete="subject"
          required
          className="block col-span-4 w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={createNoteState.subject}
          onChange={(e) => handleCreateNoteStateChange(e, "subject")}
        />
        <label htmlFor="body" className="self-start text-end">
          Body:
        </label>
        <textarea
          id="body"
          name="body"
          rows={2}
          required
          className="block col-span-4 w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={createNoteState.body}
          onChange={(e) => handleCreateNoteStateChange(e, "body")}
        />

        {createNoteError && (
          <div className="col-span-5">
            <p className="text-xs text-center text-red-500">
              *{createNoteError}
            </p>
          </div>
        )}
        {/* <div className="flex items-center col-span-2 gap-2">
          <label>Character Count: {createNoteState.body.length} / {subNoteCharacterLimit}</label>
        </div> */}
        <div className="flex items-center col-span-2 gap-2"></div>
        <button
          onClick={handleNextStepNote}
          className="w-24 px-4 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm justify-self-end hover:bg-indigo-700 ring-indigo-500 ring-1 ring-inset"
        >
          Update
        </button>
      </div>
    </>
  );
};
